import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemButton, ListItemText, CircularProgress} from '@mui/material';
import {getFirestore, collection, query, onSnapshot} from 'firebase/firestore';

import firebaseApp from '../firebase.js';

export default props => {
    const {eventUid, title, body, open, onClose, onSelect} = props;
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    const db = getFirestore(firebaseApp);

    const handleGroups = async groupsRaw => {
        const groups = groupsRaw.docs.map(group => {
            const {id} = group;
            return {
                id,
                uid: id,
                ...group.data()
            };
        });

        setGroups(groups);
        setLoading(false);
    };

    useEffect(() => {
        if (open) {
            const groupsRef = collection(db, 'events', eventUid, 'groups');
            const groupsQuery = query(groupsRef);
            const unsubscribe = onSnapshot(groupsQuery, handleGroups);

            return () => unsubscribe();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{title || 'Select Group'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {body || 'Select a group'}:
                    {!loading && <CircularProgress sx={{ml: 1}} size={20} color="primary" />}
                </DialogContentText>

                <List>
                    {groups.length ? groups.map((group, index) => {
                        const {name} = group;

                        return (
                            <ListItem key={`list-item-${index}`}>
                                <ListItemButton onClick={() => onSelect(group)}>
                                    <ListItemText primary={name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    }) : (
                        <ListItem>
                            <ListItemText primary="No groups" />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );
};