import React from 'react';
import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';

export default props => {
    const {name, rules, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, ...restField} = field;

                return (
                    <TextField
                        value={value || ''}
                        {...restField}
                        error={!!error}
                        helperText={error ? error.message : null}
                        {...rest}
                    />
                );
            }}
        />
    );
};
