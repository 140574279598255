import React, {useCallback, useState, useEffect} from 'react';
import {Container, Button, Avatar, Chip} from '@mui/material';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {GridToolbarContainer} from '@mui/x-data-grid-pro';
import moment from 'moment';
import {get} from 'lodash';
import {getFirestore, collection, query, where, orderBy} from 'firebase/firestore';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';

import firebaseApp from '../firebase.js';

import {populateCourses} from '../data/utils';

import Grid from '../components/firestore/Grid';

const functions = getFunctions(firebaseApp);
const approveRequest = httpsCallable(functions, 'approveRequest');

const db = getFirestore(firebaseApp);
const requestsRef = collection(db, 'requests');

const agoDateFormatter = params => {
    const {value} = params;
    if (!value) {
        return '-';
    }

    return moment(value.toDate()).fromNow();
};

const renderImageCell = params => {
    const {value, row} = params;
    const {name} = row;

    return (
        <Avatar src={value} alt={name}>
            <GolfCourseIcon />
        </Avatar>
    );
};

export const ApproveButton = props => {
    const {uid, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onClick = useCallback(async() => {
        setLoading(true);

        try {
            await approveRequest({
                request: uid
            });

            enqueueSnackbar('Request approved', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
            onClick={onClick}
            {...rest}
        >
            Approve
        </LoadingButton>
    );
};

const columns = [
    {field: 'imageUrl', headerName: '', width: 60, renderCell: renderImageCell, valueGetter: params => get(params, 'row.course.imageUrl')},
    {field: 'course.name', headerName: 'Course Name', flex: 1, valueGetter: params => get(params, 'row.course.name')},
    {field: 'email', headerName: 'Email', flex: 1},
    {field: 'createdAt', headerName: 'Requested', width: 150, valueFormatter: agoDateFormatter},
    {
        field: 'actions',
        width: 150,
        renderCell: params => {
            const {row} = params;
            const {uid, status} = row;

            if (status !== 'pending') {
                const color = status === 'declined' ? 'error' : 'success';
                return <Chip size="small" color={color} label={status.toUpperCase()} />;
            }

            return <ApproveButton uid={uid} />;
        },
        disableClickEventBubbling: true
    }
];

export default () => {
    const [showArchived, setShowArchived] = useState(false);
    const [gridQuery, setGridQuery] = useState(null);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button onClick={() => setShowArchived(!showArchived)}>
                    {showArchived ? 'Hide Approved & Declined Requests' : 'Show Approved & Declined Requests'}
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        const whereIn = showArchived ? ['approved', 'declined', 'pending'] : ['pending'];
        setGridQuery(query(requestsRef, where('status', 'in', whereIn), orderBy('createdAt', 'desc')));
    }, [showArchived]);

    return (
        <Container>
            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <Grid
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        query={gridQuery}
                        dataModifier={populateCourses}
                        columns={columns}
                        disableSelectionOnClick
                        onCellClick={(cell, course) => {
                            course.stopPropagation();
                        }}
                        getRowClassName={params => `request-row--${params.row.status}`}
                    />
                </div>
            </div>
        </Container>
    );
};
