import React, {useState} from "react";
import {Button, Box, Card, Container, Typography, Alert, Divider} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {getFunctions, httpsCallable} from 'firebase/functions';

import firebaseApp from '../firebase.js';

import TextField from '../form/TextField.js';
import CourseAutocomplete from '../form/CourseAutocomplete';

const functions = getFunctions(firebaseApp);
const requestCourse = httpsCallable(functions, 'requestCourse');

const Logo = () => {
    return (
        <Box sx={{flex: 1, mt: 4, mb: 4, display: 'flex', justifyContent: 'center'}}>
            <img src="/ttlogo.png" style={{width: 160}} alt="Tourney Time" />
        </Box>
    );
};

export default () => {
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [error, setError] = useState(null);

    const methods = useForm({
        defaultValues: {
            email: '',
            course: '',
            firstName: '',
            lastName: '',
            phone: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    const onSubmit = async data => {
        setLoading(true);

        try {
            await requestCourse(data);
            
            setRequested(true);
            
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }
    };

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{width: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}
                >
                    <Logo />

                    {requested ? (
                        <Alert sx={{mt: 4}} severity="success">Course request submitted. We will email you when your request has been approved.</Alert>
                    ) : (
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                            <Typography variant="h5" sx={{textAlign: 'center'}}>Course Access Request</Typography>
                            <Card sx={{p: 2, mt: 2}}>
                                <Typography variant="subtitle2" sx={{textAlign: 'center'}}>Submit the following form to gain access to manage your course on TourneyTime.</Typography>
                                <Divider sx={{my: 1}} />
                                <Typography variant="subtitle2" sx={{textAlign: 'center'}}>We may phone or email you to confirm you have authority to manage the course.</Typography>
                            </Card>
                            <CourseAutocomplete
                                label="Course"
                                name="course"
                                rules={{required: true}}
                                margin="normal"
                                fullWidth
                                disabled={loading}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                disabled={loading}
                                rules={{required: true}}
                                {...{
                                    ...error && {error: true, helperText: error}
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="First Name"
                                name="firstName"
                                type="text"
                                disabled={loading}
                                rules={{required: true}}
                                {...{
                                    ...error && {error: true, helperText: error}
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                type="text"
                                disabled={loading}
                                rules={{required: true}}
                                {...{
                                    ...error && {error: true, helperText: error}
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Phone #"
                                name="phone"
                                type="text"
                                disabled={loading}
                                rules={{required: true}}
                                {...{
                                    ...error && {error: true, helperText: error}
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 1}}
                                onClick={handleSubmit(onSubmit)}
                                disabled={loading || !isValid}
                            >
                                Request Course Access
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </FormProvider>
    );
};