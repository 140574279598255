import React, {useState, useEffect, useCallback} from 'react';
import {DataGrid, gridClasses} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {get} from 'lodash';
import {getFirestore, getDocs, collection, query, orderBy, where, limit, documentId} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {List, ListItem, ListItemText, Chip, Avatar} from '@mui/material';

import firebaseApp from '../../firebase.js';

import {DeleteButton, AddGroupToEventButton, ExportTeeSheetButton, SwitchGroupButton, MoveToSingleButton} from './common';

const functions = getFunctions(firebaseApp);
const deleteGroupFromEvent = httpsCallable(functions, 'deleteGroupFromEvent');

export default () => {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);

    const {id: eventUid} = useParams();

    const db = getFirestore(firebaseApp);

    const onDelete = useCallback(id => {
        return async() => {
            await deleteGroupFromEvent({
                event: eventUid,
                uid: id
            });
        };
    }, [eventUid]);

    let isSubscribed = true;

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        // {field: 'id', headerName: '#', flex: 1},
        {field: 'code', headerName: 'Code', flex: 1},
        {field: 'users', headerName: 'Users', flex: 3, renderCell: params => {
            const users = get(params, 'row.users', []);

            return (
                <List sx={{width: '100%'}}>
                    {users.map((user, index) => {
                        const {id, displayName, handicap = '-'} = user;

                        return (
                            <ListItem
                                key={`list-item-${index}`}
                                disablePadding
                                secondaryAction={(
                                    <div>
                                        <SwitchGroupButton eventUid={eventUid} userUid={id} sx={{ml: 1}} size="small" onSuccess={fetch} />
                                        <MoveToSingleButton eventUid={eventUid} userUid={id} sx={{ml: 1}} size="small" onSuccess={fetch} />
                                    </div>
                                )}
                            >
                                <ListItemText>
                                    {displayName}<Chip sx={{ml: 1}} size="small" avatar={<Avatar>H</Avatar>}  label={handicap} />
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
            );
        }},
        {
            field: '',
            headerName: '',
            type: 'actions',
            renderCell: params => {
                const {row} = params;
                const {id, users} = row;
    
                return <DeleteButton onDelete={onDelete(id)} disabled={users.length} />;
            },
            disableClickEventBubbling: true
        }
    ];

    const groupsRef = collection(db, 'events', eventUid, 'groups');
    const groupsQuery = query(groupsRef, orderBy('createdAt'), limit(10));
    
    const fetch = async() => {
        const groupsRaw = await getDocs(groupsQuery);

        if (isSubscribed) {
            handleGroups(groupsRaw);
        }
    };

    const handleGroups = async groupsRaw => {
        const groups = groupsRaw.docs.map(group => {
            const {id} = group;
            return {
                id,
                uid: id,
                ...group.data()
            };
        });
        
        // TODO this should be done with a single query
        if (groups.length) {
            const users = {};

            for (const group of groups) {
                const {users: userIds} = group;
                
                if (userIds.length) {
                    const usersRef = collection(db, 'users');
                    const usersQuery = query(usersRef, where(documentId(), 'in', userIds));
                    const userSnap = await getDocs(usersQuery);

                    for (const user of userSnap.docs) {
                        const {id} = user;

                        users[id] = {
                            id,
                            uid: id,
                            ...user.data()
                        };
                    }
                }
            }

            if (isSubscribed) {
                setGroups(groups.map(group => {
                    const {users: userIds, ...rest} = group;

                    return {
                        users: userIds.map(user => users[user]),
                        ...rest
                    };
                }));
            }
        } else if (isSubscribed) {
            setGroups([]);
        }

        if (isSubscribed) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetch();

        return () => isSubscribed = false;
    }, [eventUid]);

    return (
        <div style={{width: '100%'}}>
            <DataGrid
                loading={loading}
                autoHeight
                getRowHeight={() => 'auto'}
                rows={groups}
                columns={columns}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                }}
                disableSelectionOnClick
            />

            <AddGroupToEventButton sx={{mt: 1}} uid={eventUid} onSuccess={fetch} />
            <ExportTeeSheetButton sx={{mt: 1, ml: 1}} uid={eventUid} />
        </div>
    );
};