import React, {useState, useEffect} from "react";
import {Button, Box, Container, Alert, CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {getAuth, verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword, applyActionCode} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';
import queryString from 'query-string';
import {getFunctions, httpsCallable} from 'firebase/functions';

import firebaseApp from '../firebase.js';

import TextField from '../form/TextField.js';

import {coursesRef, fetchDocumentsWithIds} from '../data/utils';

const functions = getFunctions(firebaseApp);
const getUserByEmail = httpsCallable(functions, 'getUserByEmail');

const Logo = () => {
    return (
        <Box sx={{flex: 1, mt: 4, mb: 4, display: 'flex', justifyContent: 'center'}}>
            <img src="/ttlogo.png" style={{width: 160}} alt="Tourney Time" />
        </Box>
    );
};

const LoadingIndicator = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
        </Box>
    );
};

const ResetPassword = props => {
    const {oobCode, continueUrl} = props;

    const [validating, setValidating] = useState(true);
    const [validCode, setValidCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [reset, setReset] = useState(false);
    const navigate = useNavigate();

    const auth = getAuth(firebaseApp);

    const methods = useForm({
        defaultValues: {
            email: '',
            password: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    const onSubmit = async data => {
        setLoading(true);

        const {password} = data;

        try {
            await confirmPasswordReset(auth, oobCode, password);
            setReset(true);

            if (user && user.email) {
                await signInWithEmailAndPassword(auth, user.email, password);
                navigate('/course');

                return;
            }

            if (continueUrl) {
                setTimeout(() => {
                    window.location = continueUrl;
                }, 1000);
            }
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }
    };

    useEffect(() => {
        const go = async() => {
            try {
                const email = await verifyPasswordResetCode(auth, oobCode);
                const {data} = await getUserByEmail({email});
                const {role, course: courseUid} = data;

                if (role === 'COURSE' && courseUid) {
                    const [course] = await fetchDocumentsWithIds(coursesRef, [courseUid]);
                    setUser({
                        ...data,
                        course
                    });
                }
                
                setValidCode(true);
                setValidating(false);
            } catch(e) {
                setValidCode(false);
                setValidating(false);
            }
        };

        go();
    }, []);

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{width: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}
                >
                    <Logo />

                    {validating === true && <LoadingIndicator />}

                    {reset === true && <Alert sx={{mt: 4}} severity="success">Password reset successfully!</Alert>}

                    {validating === false && validCode === false && <Alert sx={{mt: 4}} severity="error">Something went wrong. Please try again!</Alert>}

                    {validating === false && validCode === true && reset !== true && (
                        <>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="New Password"
                                    name="password"
                                    type="password"
                                    disabled={loading}
                                    rules={{required: true}}
                                    {...{
                                        ...error && {error: true, helperText: error}
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirm New Password"
                                    name="confirmPassword"
                                    type="password"
                                    disabled={loading}
                                    rules={{required: true}}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 1}}
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={loading || !isValid}
                                >
                                    {user ? 'Set Password' : 'Reset Password'}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </FormProvider>
    );
};

const VerifyEmail = props => {
    const {oobCode} = props;

    const [validating, setValidating] = useState(true);
    const [validCode, setValidCode] = useState(null);

    const auth = getAuth(firebaseApp);

    useEffect(() => {
        const go = async() => {
            try {
                await applyActionCode(auth, oobCode);
                setValidCode(true);
                setValidating(false);
            } catch(e) {
                setValidCode(false);
                setValidating(false);
            }
        };

        go();
    }, []);

    return (
        <Container component="main" maxWidth="s" sx={{width: 400}}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                <Logo />

                {validating === true && <LoadingIndicator />}

                {validating === false && validCode === true && <Alert sx={{mt: 4}} severity="success">Email verified successfully!</Alert>}

                {validating === false && validCode === false && <Alert sx={{mt: 4}} severity="error">Something went wrong. Please try again!</Alert>}
            </Box>
        </Container>
    );
};

export default () => {
    const parsed = queryString.parse(window.location.search) || {};
    const {mode} = parsed;

    if (mode === 'resetPassword') {
        return <ResetPassword {...parsed} />;
    } else if (mode === 'verifyEmail') {
        return <VerifyEmail {...parsed} />;
    }

    window.location = 'https://tourneytimeapp.com';
};