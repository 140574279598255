import React, {useState, useEffect, useContext} from 'react';
import {Container, Box, Button, Grid, CircularProgress} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider} from 'react-hook-form';
import {getFirestore, doc, getDoc, updateDoc} from 'firebase/firestore';

import firebaseApp from '../firebase.js';

import TextField from '../form/TextField.js';
import SelectField from '../form/SelectField.js';
import CourseAutocomplete from '../form/CourseAutocomplete';

import {UserContext} from '../contexts/userContext';

export default props => {
    const {isProfile} = props;

    const {id} = useParams();
    const isNew = id === 'new';

    const navigate = useNavigate();
    const [loading, setLoading] = useState(!isNew);
    const [saving, setSaving] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const db = getFirestore(firebaseApp);

    const functions = getFunctions(firebaseApp);
    const getUser = httpsCallable(functions, 'getUser');
    const updateUser = httpsCallable(functions, 'updateUser');
    const addUser = httpsCallable(functions, 'addUser');
    const sendUserVerificationEmail = httpsCallable(functions, 'sendUserVerificationEmail');
    const deleteUser = httpsCallable(functions, 'deleteUser');

    const methods = useForm({
        defaultValues: {
            displayName: '',
            email: '',
            course: '',
            role: 'USER',
            _course: {},
            deleted: false,
            emailVerified: false
        },
        mode: 'onBlur'
    });

    const {userState} = useContext(UserContext);
    const {userId, userData} = userState;
    const {role} = userData || {};
    const isAdmin = role === 'ADMIN';

    const {handleSubmit, reset, watch} = methods;

    const isCourse = watch('role') === 'COURSE';
    const password = watch('password');
    const emailVerified = watch('emailVerified');
    const deleted = watch('deleted');

    useEffect(() => {
        async function fetchUser() {
            try {
                const {data} = await getUser({user: isProfile ? userId : id});
                const {course, role} = data || {};
                const isCourse = role === 'COURSE';

                if (isCourse && !isNew && course) {
                    const courseRef = doc(db, 'courses', course);
                    const docSnap = await getDoc(courseRef);

                    if (!docSnap.exists()) {
                        enqueueSnackbar('Course not found', {variant: 'error'});
                    } else {
                        data._course = docSnap.data();
                    }
                }

                reset({
                    id,
                    ...data
                });
            } catch(e) {
                navigate('/users');
                return;
            }

            setLoading(false);
        };

        if (!isNew) {
            fetchUser();
        }
    }, []);

    const onSubmit = async data => {
        try {
            setSaving(true);

            if (isNew) {
                await addUser(data);
            } else {
                const {id, _course, ...rest} = data;
                const {course} = data;

                await updateUser({user: id, data: rest});
                
                if (isCourse && course && _course) {
                    const {name, address, city, state, zip, website, email, phone, fax, holes} = _course;

                    const courseRef = doc(db, 'courses', course);

                    await updateDoc(courseRef, {
                        name,
                        address,
                        city,
                        state,
                        zip,
                        website,
                        email,
                        phone,
                        fax,
                        holes,
                        updatedAt: new Date()
                    });
                }
            }

            navigate('/users');
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            
            setSaving(false);
        }
    };

    const handleVerification = async () => {
        setVerifying(true);

        try {
            await sendUserVerificationEmail({user: id});

            enqueueSnackbar('Successfully sent verification email', {variant: 'success'});
        } catch(e) {
            console.warn(e);

            enqueueSnackbar(e, {variant: 'error'});
        }
        
        setVerifying(false);
    };

    const onDelete = async () => {
        setDeleting(true);

        try {
            await deleteUser({user: id});

            enqueueSnackbar('Successfully deleted user', {variant: 'success'});

            navigate('/users');
        } catch(e) {
            console.warn(e);

            enqueueSnackbar(e, {variant: 'error'});
        }
        
        setDeleting(false);
    };

    const handleDelete = async () => {
        enqueueSnackbar('Are you sure you want to delete this user? This cannot be undone.', {
            variant: 'warning', 
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete User
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    };

    return (
        <FormProvider {...methods}>
            <Container>
                {loading ? (
                    <Box sx={{mt: 3, display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress sx={{ml: 1}} size={28} color="primary" />
                    </Box>
                ) : (
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    rules={{required: true}}
                                    label="Email"
                                    name="email"
                                    disabled={isProfile || saving || loading}
                                />

                                {!isCourse && (
                                    <>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <TextField
                                                sx={{mr: 1}}
                                                margin="normal"
                                                rules={{required: !isCourse}}
                                                fullWidth
                                                label="First Name"
                                                name="firstName"
                                                disabled={saving || loading}
                                            />
                                            <TextField
                                                margin="normal"
                                                rules={{required: !isCourse}}
                                                fullWidth
                                                label="Last Name"
                                                name="lastName"
                                                disabled={saving || loading}
                                            />
                                        </Box>
                                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                            <TextField
                                                sx={{mr: 1}}
                                                margin="normal"
                                                fullWidth
                                                label="Location"
                                                name="location"
                                                disabled={isProfile || saving || loading}
                                            />
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Handicap"
                                                name="handicap"
                                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                                rules={{
                                                    validate: value => value >= 0 && value <= 54 ? true : 'Must be a number from 0 to 54'
                                                }}
                                                type="number"
                                                disabled={isProfile || saving || loading}
                                            />
                                        </Box>
                                    </>
                                )}
                                {isAdmin && (
                                    <SelectField
                                        margin="normal"
                                        fullWidth
                                        label="Role"
                                        name="role"
                                        disabled={saving}
                                        options={[
                                            {value: 'USER', label: 'User'},
                                            {value: 'ADMIN', label: 'Admin'},
                                            {value: 'BUSINESS', label: 'Business'},
                                            {value: 'COURSE', label: 'Course'}
                                        ]}
                                    />
                                )}
                                {isAdmin && isCourse && (
                                    <CourseAutocomplete
                                        label="Course"
                                        name="course"
                                        rules={{required: isCourse}}
                                        margin="normal"
                                        fullWidth
                                        disabled={saving}
                                    />
                                )}
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label={isNew ? 'Password' : 'New Password'}
                                    rules={{required: isNew}}
                                    name="password"
                                    disabled={saving || loading}
                                />
                                {!!password && (
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        label={isNew ? 'Confirm Password' : 'Confirm New Password'}
                                        rules={{
                                            required: password ? 'Confirm password is required' : null,
                                            validate: value => {
                                                if (!password) {
                                                    return true;
                                                }
                
                                                if (value !== password) {
                                                    return 'Passwords do not match';
                                                }
                
                                                return true;
                                            }
                                        }}
                                        name="confirmPassword"
                                        disabled={saving || loading}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                            {!isNew && !emailVerified && isAdmin && (
                                <Button
                                    onClick={handleVerification}
                                    disabled={saving || verifying}
                                    sx={{mr: 2}}
                                >
                                    Resend Verification Email
                                    {saving && <CircularProgress sx={{ml: 1}} size={12} color="inherit" />}
                                </Button>
                            )}

                            {!isNew && !deleted && isAdmin && (
                                <Button
                                    onClick={handleDelete}
                                    variant="outlined"
                                    color="error"
                                    disabled={saving || deleting}
                                    sx={{mr: 2}}
                                >
                                    Delete User
                                    {deleting && <CircularProgress sx={{ml: 1}} size={12} color="inherit" />}
                                </Button>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={saving}
                            >
                                Save
                                {saving && <CircularProgress sx={{ml: 1}} size={12} color="inherit" />}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Container>
        </FormProvider>
    );
};