import {initializeApp} from 'firebase/app'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
// import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
    storageBucket: 'gs://tourney-time.appspot.com'
};

const firebaseApp = initializeApp(config);

if (process.env.NODE_ENV === 'development') {
    // const functions = getFunctions(firebaseApp);
    // connectFunctionsEmulator(functions, 'localhost', 5001);

    // const firestore = getFirestore(firebaseApp);
    // connectFirestoreEmulator(firestore, 'localhost', 8082);
}

export default firebaseApp;








// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAIc7lkJanyW_yZ4haCxHgZ-P7bTm8ED5o",
//   authDomain: "tourney-time-dev.firebaseapp.com",
//   projectId: "tourney-time-dev",
//   storageBucket: "tourney-time-dev.appspot.com",
//   messagingSenderId: "43461229257",
//   appId: "1:43461229257:web:5983f17e0fe25db0fb2b18"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);