import React, {useState, useEffect, useCallback} from 'react';
import {DataGrid} from '@mui/x-data-grid';
// import {DataGridPro} from '@mui/x-data-grid-pro';
import {getDocs, query, limit, startAfter, limitToLast, endBefore} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

export default props => {
    const {query: fireStoreQuery, dataModifier, ...rest} = props;
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const [rowCount, setRowCount] = useState(Number.MAX_VALUE);
    const [first, setFirst] = useState(null);
    const [last, setLast] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0
    });

    let isSubscribed = true;

    const handleRaw = async raw => {
        let rows = [];
        raw.forEach(row => {
            rows.push({
                id: row.id,
                uid: row.id,
                ...row.data()
            });
        });

        if (dataModifier) {
            rows = await dataModifier(rows);
        }

        const length = rows.length;
        setFirst(raw.docs[0]);

        if (length > 0) {
            setLast(raw.docs[length - 1]);
        }

        const {pageSize, page} = paginationModel;
        if (length < pageSize) {
            setRowCount((pageSize * page) + length);
        } else {
            setRowCount(Number.MAX_VALUE);
        }

        if (isSubscribed) {
            setRows(rows);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!fireStoreQuery) {
            setLoading(false);
            return;
        }

        setLoading(true);

        const {pageSize, page} = paginationModel;

        let limitedQuery;
        if (page > 0) {
            if (last) {
                limitedQuery = query(fireStoreQuery, startAfter(last), limit(pageSize));
            } else if (first) {
                limitedQuery = query(fireStoreQuery, endBefore(first), limitToLast(pageSize));
            }
        } else {
            limitedQuery = query(fireStoreQuery, limit(pageSize));
        }

        const fetch = async() => {
            try {
                const raw = await getDocs(limitedQuery);
                
                if (isSubscribed) {
                    await handleRaw(raw);
                }
            } catch (e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [paginationModel]);

    useEffect(() => {
        if (fireStoreQuery) {
            setPaginationModel({
                pageSize: 25,
                page: 0
            });
        }
    }, [fireStoreQuery]);

    const handlePaginationModelChange = useCallback((newPaginationModel) => {
        if (newPaginationModel.page < paginationModel.page) {
            setLast(null);
        } else {
            setFirst(null);
        }

        setPaginationModel(newPaginationModel);
    });

    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{flexGrow: 1, minHeight: 400}}>
                <DataGrid
                    loading={loading}
                    rows={rows}
                    autoHeight
                    disableSelectionOnClick
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[5, 10, 25]}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: ({from, to, count}) => `${from} - ${to} of ${count === Number.MAX_VALUE ? "many" : count}`
                        }
                    }}
                    {...rest}
                />
            </div>
        </div>
    );
}