import React, {useState, useEffect, useCallback, forwardRef} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {throttle} from 'lodash';
import algoliasearch from 'algoliasearch';
import {getFirestore, getDoc, doc} from 'firebase/firestore';
import {Controller} from 'react-hook-form';

import firebaseApp from '../firebase.js';

const MinLength = 3;

const UncontroledComponent = forwardRef((props, ref) => {
    const {value: rawValue, onChange, disabled, ...rest} = props;

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [noOptionsText, setNoOptionsText] = useState('Start typing to search for a course');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const client = algoliasearch('V0HTM0BKX4', 'ee12d8e047b5ed41f4a1820f671d14f2');
    const index = client.initIndex('coursesNameIndex');

    const db = getFirestore(firebaseApp);

    let isSubscribed = true;

    const fetchOptions = useCallback(throttle(async(inputValue) => {
        const {hits} = await index.search(inputValue);
        const options = hits.map(hit => {
            const {objectID: id, name, city} = hit;

            return {
                id,
                name: `${name} (${city})`
            }
        });

        if (isSubscribed) {
            const value = options.find(option => option.id === rawValue);
            if (!value) {
                setValue(null);
            }

            setOptions(options);

            setLoading(false);
        }
    }, 1000, {leading: false, trailing: true}), []);

    useEffect(() => {
        isSubscribed = true;

        if (rawValue) {
            if (!value && isSubscribed) {
                setOptions([{id: rawValue}]);
                setValue({id: rawValue});
                setInputValue(rawValue);

                const findValue = async() => {
                    try {
                        const docRef = doc(db, 'courses', rawValue);
                        const docSnap = await getDoc(docRef);

                        if (docSnap.exists() && isSubscribed) {
                            const data = docSnap.data();
                            const {name} = data;

                            const option = {
                                id: rawValue,
                                ...data
                            };
            
                            setOptions([option]);
                            setValue(option)
                            setInputValue(name);
                        }
                    } catch(e) {
                        //
                    }
    
                    if (isSubscribed) {
                        setLoading(false);
                    }
                };
                
                if (isSubscribed) {
                    findValue();
                    setLoading(true);
                }
            }
        }

        return () => isSubscribed = false;
    }, [rawValue]);

    useEffect(() => {
        if (isSubscribed) {
            if (inputValue === '') {
                setNoOptionsText('Start typing to search for a course');
            } else if (inputValue.length < MinLength) {
                setNoOptionsText(`Type at least ${MinLength} characters to search for a course`);
            } else {
                setNoOptionsText('No courses found');
            }
        }
    }, [inputValue]);

    return (
        <Autocomplete
            ref={ref}
            value={value}
            inputValue={inputValue}
            getOptionLabel={option => option.name || option.id || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={options}
            onChange={(e, newValue) => {
                setValue(newValue);
                onChange(e, newValue ? newValue.id : undefined);
            }}
            disabled={disabled}
            loading={loading}
            open={open}
            filterOptions={(x) => x}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onInputChange={(e, newInputValue, reason) => {
                setInputValue(newInputValue);

                if (reason === 'input') {
                    if (newInputValue !== '' && newInputValue.length >= MinLength) {
                        setLoading(true);
                        fetchOptions(newInputValue);
                    } else {
                        setOptions([])
                    }
                } else if (reason === 'clear') {
                    setOptions([]);
                }
            }}
            noOptionsText={noOptionsText}
            renderInput={params => (
                <TextField
                    label="Course"
                    {...params}
                    {...rest}
                />
            )}
        />
    );
});

export default props => {
    const {name, rules, onChange, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => (
                <UncontroledComponent
                    {...field}
                    onChange={(e, option) => {
                        field.onChange(option);

                        if (onChange) {
                            onChange(option);
                        }
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    {...rest}
                />
            )}
        />
    );
};