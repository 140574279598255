import React, {useState, useEffect} from 'react';
import {Container, Divider, CircularProgress, Box, Typography, Button} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import mapboxgl from 'mapbox-gl';
// import Map, {Marker} from 'react-map-gl';
import PersonIcon from '@mui/icons-material/Person';

import 'mapbox-gl/dist/mapbox-gl.css';

import firebaseApp from '../firebase.js';

import {EventContext} from '../contexts/eventContext';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default () => {
    const {id: rawId} = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const db = getFirestore(firebaseApp);
    const [event, setEvent] = useState(null);

    let id = rawId;
    if (['szPpftI6xtrsHTyn124V', 'szPpftl6xtrsHtyn124V'].includes(id)) {
        id = 'szPpftI6xtrsHTynI24V';
    }

    const eventRef = doc(db, 'events', id);

    useEffect(() => {
        let isSubscribed = true;

        async function fetchEvent() {
            const eventRaw = await getDoc(eventRef);
            if (!eventRaw.exists()) {
                window.location.href = 'https://tourneytimeapp.com';
                return;
            }

            const {date, createdAt, course: courseId, user: userId, ...rest} = eventRaw.data();
            let course;
            if (courseId) {
                const courseRef = doc(db, 'courses', courseId);
                const courseRaw = await getDoc(courseRef);

                const {fee, ...restCourse} = courseRaw.data();

                course = {
                    id: courseId,
                    fee: fee / 100,
                    ...restCourse
                };
            }

            let user;
            if (userId) {
                const userRef = doc(db, 'users', userId);
                const userRaw = await getDoc(userRef);

                user = userRaw.data();
            }

            if (isSubscribed) {
                setEvent({
                    id,
                    ...rest,
                    date: date.toDate(),
                    createdAt: createdAt.toDate(),
                    course,
                    user
                });

                setLoading(false);
            }

            document.title = `${rest.title} | Tourney Time`;
        };

        if (isSubscribed) {
            fetchEvent();
        }

        return () => isSubscribed = false;
    }, []);

    if (loading) {
        return (
            <Container sx={{height: '100vh', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress />
            </Container>
        )
    }

    const {title, date, body, course, user} = event || {};
    let {imageUrl} = event || {};
    // const {_geoloc} = course || {};
    const {displayName} = user || {};
    // const {lat, lng} = _geoloc || {};

    if (!imageUrl) {
        imageUrl = course.imageUrl;
    }

    // <img src="ttlogo-horizontal.png" alt="Tiger Taekwondo" />

    // <Map
    //     mapboxAccessToken="pk.eyJ1IjoicmRvdWdhbiIsImEiOiJjbGVxbzBpNm4wa2MyM3dxcm1xYmxqNzFjIn0.e6IFIbWEaCq7xENTiy_xUA"
    //     initialViewState={{
    //         longitude: lng,
    //         latitude: lat,
    //         zoom: 11
    //     }}
    //     mapStyle="mapbox://styles/mapbox/streets-v9"
    // >   
    //     <Marker
    //         longitude={lng}
    //         latitude={lat}
    //         anchor="bottom"
    //         >
    //     </Marker>
    // </Map>

    return (
        <>
            <Box sx={{background: '#fff'}}>
                <EventContext.Provider value={event}>
                    <Container disableGutters maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: '#fff'}}>
                        <Box sx={{mt: 4, mb: 4}}>
                            <img src="/ttlogo.png" style={{width: 160}} alt="Tourney Time" />
                        </Box>

                        <Typography sx={{fontSize: 18, mb: 4, pl: 2, pr: 2, textAlign: 'center'}}>
                            You’ve been invited to <strong>{title}</strong>
                        </Typography>

                        <Box sx={{mb: 4, ml: 1, mr: 1, overflow: 'hidden', boxShadow: '0 3px 5px rgb(0,0,0,0.1)', borderRadius: '12px'}}>
                            <Box sx={{backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '300px'}} />

                            <Box sx={{padding: 2}}>
                                <Typography sx={{fontSize: 18, fontWeight: 700}}>
                                    {title}
                                </Typography>
                                <Typography sx={{mb: 1, fontSize: 16, fontWeight: 600}}>
                                    {course && course.name}
                                </Typography>
                                <Typography sx={{mb: 1, fontSize: 16}}>
                                    {date && moment(date).format('LL')}
                                </Typography>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <PersonIcon sx={{mr: 0.5, color: '#686868'}} /> 
                                    <Typography sx={{fontSize: 14, color: '#686868'}}>
                                        Organized by <Typography component="span" sx={{color: '#000', fontSize: 14, fontWeight: 600}}>{displayName}</Typography>
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />

                            <Box sx={{padding: 2}}>
                                <Typography sx={{fontSize: 18, fontWeight: 700, mb: 1}}>
                                    About the tournament
                                </Typography>

                                <Typography sx={{fontSize: 14}}>
                                    {body}
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography sx={{fontSize: 14, textAlign: 'center'}}>
                                    This tournament is hosted on <strong>Tourney Time</strong> app. You can download the app via the Apple App Store or Google Play Store.
                                </Typography>
                            </Box>

                            <Box sx={{padding: 2, paddingTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button 
                                    variant="contained"
                                    sx={{
                                        width: 190,
                                        ml: 2,
                                        background: '#2f6b5d',
                                        fontFamily: 'Inter',
                                        fontWeight: 700,
                                        '&:hover': {
                                            background: '#2f6b5d'
                                        }
                                    }}
                                    onClick={() => window.open('https://apps.apple.com/us/app/tourney-time/id1617211529')}
                                >
                                    Download
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </EventContext.Provider>
            </Box>
        </>
    );
};
