import React, {useState, useEffect, useContext, createRef} from 'react';
import {Container, Typography, Divider, Box, Button, Card, Grid, CardMedia, CardActionArea, CircularProgress} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {getFirestore, doc, getDoc, collection, addDoc, updateDoc, deleteDoc} from 'firebase/firestore';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider} from 'react-hook-form';
import {omit, omitBy, isNil} from 'lodash';

import firebaseApp from '../firebase.js';

import {UserContext} from '../contexts/userContext';

import TextField from '../form/TextField.js';
import FileField from '../form/FileField.js';
import CurrencyField from '../form/CurrencyField.js';

export default props => {
    const {self} = props;
    const navigate = useNavigate();
    let {id} = useParams();
    const {userState} = useContext(UserContext);
    const {userData} = userState;
    const {role, course} = userData || {};

    if (self) {
        if (!course) {
            navigate('/');
            return;
        }

        ({id} = course);
    }

    const isAdmin = role === 'ADMIN';
    const isNew = id === 'new';
    const [loading, setLoading] = useState(!isNew);
    const [saving, setSaving] = useState(false);
    const storage = getStorage(firebaseApp);
    const db = getFirestore(firebaseApp);
    const courseRef = !isNew && doc(db, 'courses', id);
    const imageUploadRef = createRef();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const methods = useForm({
        defaultValues: {
            name: '',
            city: '',
            state: 'British Columbia',
            country: 'Canada',
            zip: '',
            imageUrl: '',
            imageFile: '',
            fee: 0
        },
        mode: 'onBlur'
    });
    const {handleSubmit, reset, watch, formState} = methods;

    const imageUrl = watch('imageUrl');
    const imageFile = watch('imageFile');

    useEffect(() => {
        async function fetchCourse() {
            const docSnap = await getDoc(courseRef);
            if (!docSnap.exists()) {
                navigate(self ? '/' : '/courses');
                return;
            }

            const {fee, ...rest} = docSnap.data();

            reset({
                id,
                ...rest,
                fee: fee ? fee / 100 : 0
            });

            setLoading(false);
        }

        if (!isNew) {
            fetchCourse();
        }
    }, []);

    const handleImageUpload = () => {
        imageUploadRef.current.click();
    };

    const onSubmit = async data => {
        const {dirtyFields} = formState;
        if (!isNew && !Object.keys(dirtyFields).length && !imageFile) {
            return;
        }

        try {
            setSaving(true);

            let imageUrl;
            const now = new Date();

            let newData = Object.keys(dirtyFields).reduce((result, field) => {
                const value = data[field];

                if (field === 'fee') {
                    result[field] = value * 100;
                } else {
                    result[field] = value;
                }

                return result;
            }, {});

            if (isNew) {
                newData = isNew ? omitBy(data, isNil) : omit(newData, ['imageFile']);
            }

            newData = omit(newData, ['imageFile']);

            if (isNew) {
                await addDoc(collection(db, 'courses'), {
                    ...newData,
                    createdAt: now,
                    updatedAt: now,
                    ...imageUrl && {imageUrl}
                });
            } else {
                if (imageFile) {
                    const reference = ref(storage, `/courses/${data.id}.jpg`);
                    await uploadBytes(reference, imageFile);
                    
                    imageUrl = await getDownloadURL(reference);
                }

                if (Object.keys(dirtyFields).length) {
                    await updateDoc(courseRef, {
                        ...newData,
                        updatedAt: now,
                        ...imageUrl && {imageUrl}
                    });
                }
            }

            navigate(self ? '/' : '/courses');
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            
            setSaving(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <Container>
                {loading && <CircularProgress sx={{ml: 1}} size={28} color="primary" />}

                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                    <Grid container spacing={2}>
                        {!isNew && (
                            <Grid item xs={4}>
                                <Card>
                                    <CardActionArea
                                        sx={{minHeight: 260, display: 'flex', alignItems: 'center'}}
                                        onClick={handleImageUpload}
                                    >
                                        {(imageFile || imageUrl) ? (
                                            <>
                                                <CardMedia
                                                    component="img"
                                                    height={260}
                                                    image={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
                                                />
                                            </>
                                        ) : (
                                            <Typography variant="button">UPLOAD IMAGE</Typography>
                                        )}
                                    </CardActionArea>
                                    <FileField ref={imageUploadRef} name="imageFile" />
                                </Card>
                            </Grid>
                        )}
                        <Grid item xs={isNew ? 12 : 8}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Name"
                                name="name"
                                disabled={saving || loading}
                            />

                            <Typography variant="overline" display="block" sx={{mt: 2}}>Location</Typography>
                            <Divider />
                            <Box sx={{
                                display: 'grid',
                                columnGap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="City"
                                    name="city"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="State"
                                    name="state"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Zipcode"
                                    name="zip"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Country"
                                    name="country"
                                    disabled={saving || loading}
                                />
                            </Box>

                            <Typography variant="overline" display="block" sx={{mt: 2}}>Contact Information</Typography>
                            <Divider />
                            <Box sx={{
                                display: 'grid',
                                columnGap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Website"
                                    name="website"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    disabled={saving || loading}
                                />
                            </Box>
                            <Box sx={{
                                display: 'grid',
                                columnGap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Fax"
                                    name="fax"
                                    disabled={saving || loading}
                                />
                            </Box>

                            <Typography variant="overline" display="block" sx={{mt: 2}}>Course Information</Typography>
                            <Divider />
                            <Box sx={{
                                display: 'grid',
                                columnGap: 2,
                                gridTemplateColumns: 'repeat(2, 1fr)',
                            }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Holes"
                                    name="holes"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Par"
                                    name="par"
                                    disabled={saving || loading}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Length"
                                    name="length"
                                    disabled={saving || loading}
                                />
                                <CurrencyField
                                    sx={{mt: 2}}
                                    fullWidth
                                    label="Green Fee"
                                    name="fee"
                                    disabled={saving}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                        {!isNew && isAdmin && (
                            <Button
                                variant="text"
                                color="error"
                                size="small"
                                sx={{mr: 1}}
                                onClick={async () => {
                                    const onDelete = async() => {
                                        try {
                                            await deleteDoc(courseRef);
                                            navigate(`/courses`);

                                            enqueueSnackbar('Course Deleted', {variant: 'success'});
                                        } catch(e) {
                                            enqueueSnackbar(e.message, {variant: 'error'});
                                        }
                                    };

                                    enqueueSnackbar('Are you sure you want to cancel this event?', {
                                        variant: 'warning',
                                        action: key => {
                                            return (
                                                <>
                                                    <Button onClick={() => {
                                                        closeSnackbar(key);
                                                        onDelete();
                                                    }}>
                                                        Delete Course
                                                    </Button>
                                                    <Button onClick={() => closeSnackbar(key)}>
                                                        Dismiss
                                                    </Button>
                                                </>
                                            );
                                        }
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        )}

                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            disabled={saving}
                        >
                            Save
                            {saving && <CircularProgress sx={{ml: 1}} size={12} color="inherit" />}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </FormProvider>
    );
};
