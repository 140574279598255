import React, {forwardRef} from 'react';
import {TextField, InputAdornment} from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import {Controller} from 'react-hook-form';

const CurrencyInputCustom = forwardRef((props, ref) => {
    const {inputRef, onChange, ...rest} = props;

    return (
        <CurrencyInput
            ref={ref}
            {...rest}
            step={1}
            onValueChange={(value) => {
                onChange(value);
            }}
        />
    );
});

export default forwardRef((props, ref) => {
    const {name, rules, InputProps, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, ...restField} = field;

                return (
                    <TextField
                        InputProps={{
                            ...InputProps,
                            startAdornment: (
                                <InputAdornment position="start">$</InputAdornment>
                            ),
                            inputComponent: CurrencyInputCustom
                        }}
                        value={value || 0}
                        {...restField}
                        ref={ref}
                        error={!!error}
                        helperText={error ? error.message : null}
                        {...rest}
                    />
                );
            }}
        />
    );
});
