import React, {useEffect, useState, useCallback} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';

import TextField from '../form/TextField';

export default props => {
    const {title, description, saveText, open, onClose, onSelect, onTextChange} = props;

    const [loading, setLoading] = useState(false);

    const methods = useForm({
        defaultValues: {
            text: ''
        },
        mode: 'onBlur'
    });
    const {handleSubmit, reset, watch} = methods;

    useEffect(() => {
        if (open) {
            reset({text: ''});
            setLoading(false);
        }
    }, [open]);

    const onSubmit = useCallback(data => {
        const {text} = data;
        onSelect(text);
        setLoading(true);
    });

    const text = watch('text');

    useEffect(() => {
        if (onTextChange) {
            onTextChange(text);
        }
    }, [text]);

    return (
        <FormProvider {...methods}>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {description}
                    </DialogContentText>

                    <TextField
                        disabled={loading}
                        fullWidth
                        sx={{mt: 2}}
                        rules={{required: true}}
                        name="text"
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onClose}>Cancel</Button>
                    <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>{saveText}</LoadingButton>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};