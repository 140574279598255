import React, {useState, useEffect, useCallback} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {getFirestore, doc, getDoc, collection, query, onSnapshot, orderBy} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';
import {Chip, Avatar} from '@mui/material';

import firebaseApp from '../../firebase.js';

import UserSelection from '../../modals/UserSelection';

import {DeleteButton} from './common';

const functions = getFunctions(firebaseApp);
const inviteToEvent = httpsCallable(functions, 'inviteToEvent');
const deleteAttendee = httpsCallable(functions, 'deleteAttendee');

export default () => {
    const [loading, setLoading] = useState(true);
    const [invitingUser, setInvitingUser] = useState(false);
    const [attendees, setAttendees] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {id: eventUid} = useParams();

    const db = getFirestore(firebaseApp);

    const [userSelectionOpen, setUserSelectionOpen] = useState(false);

    const onDelete = useCallback(attendeeUid => {
        return async() => {
            await deleteAttendee({
                event: eventUid,
                user: attendeeUid
            });
        };
    }, [eventUid, attendees]);

    const attendeeColumns = [
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            valueGetter: params => {
                const {row} = params;
                const {owner, invitedAt, status} = row;

                if (owner) {
                    return 'Owner';
                }

                if (status === 'invited') {
                    return `Invited ${invitedAt.toDate().toLocaleString()}`;
                }

                return status;
            }
        },
        {
            field: 'user.displayName',
            headerName: 'User',
            flex: 2,
            renderCell: params => {
                const {row} = params;
                const {user} = row;
                const {displayName, handicap} = user;

                return (
                    <>
                        {displayName}
                        <Chip sx={{ml: 1}} size="small" avatar={<Avatar>H</Avatar>}  label={handicap} />
                    </>
                );
            }
        },
        {
            field: '',
            headerName: '',
            type: 'actions',
            renderCell: params => {
                const {row} = params;
                const {user} = row;
                const {uid} = user;
    
                return <DeleteButton onDelete={onDelete(uid)} />;
            },
            disableClickEventBubbling: true
        }
    ];

    const handleAttendees = async attendeesRaw => {
        const attendees = attendeesRaw.docs.map(attendee => {
            const {id} = attendee;
            return {
                id,
                uid: id,
                ...attendee.data()
            };
        });
        
        // TODO this should be done with a single query
        if (attendees.length) {
            const users = {};
            for (const attendee of attendees) {
                const {user} = attendee;
                const userRef = doc(db, 'users', user);
                const userSnap = await getDoc(userRef);
                users[user] = {
                    id: userSnap.id,
                    uid: userSnap.id,
                    ...userSnap.data()
                };
            }

            setAttendees(attendees.map(attendee => {
                const {user, ...rest} = attendee;

                return {
                    user: users[user],
                    ...rest
                };
            }));
        } else {
            setAttendees([]);
        }

        setLoading(false);
    };

    useEffect(() => {
        const attendeesRef = collection(db, 'events', eventUid, 'attendees');
        const attendeesQuery = query(attendeesRef, orderBy('createdAt'));
        const unsubscribe = onSnapshot(attendeesQuery, attendeesRaw => {
            handleAttendees(attendeesRaw);
        });

        return () => unsubscribe();
    }, []);

    const onInviteUser = async() => {
        setUserSelectionOpen(true);
    };

    const onInvite = async userUid => {
        setUserSelectionOpen(false);

        try {
            setInvitingUser(true);

            await inviteToEvent({
                event: eventUid,
                user: userUid
            });

            enqueueSnackbar('Invited to Event', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setInvitingUser(false);
    };

    return (
        <div style={{width: '100%'}}>
            <DataGrid
                loading={loading}
                autoHeight
                hideFooter
                rows={attendees}
                columns={attendeeColumns}
                disableSelectionOnClick
            />

            <LoadingButton
                variant="contained"
                size="small"
                sx={{mt: 1}}
                onClick={onInviteUser}
                loading={invitingUser}
            >
                Invite User
            </LoadingButton>

            <UserSelection
                open={userSelectionOpen}
                onClose={() => setUserSelectionOpen(false)}
                onSelect={onInvite}
            />
        </div>
    );
};