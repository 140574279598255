import React, {forwardRef} from 'react';
import {Controller} from 'react-hook-form';

export default forwardRef((props, ref) => {
    const {name, rules, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {onChange, value, ...restField} = field;

                return (
                    <input
                        type="file"
                        hidden
                        {...rest}
                        {...restField}
                        ref={ref}
                        onChange={e => {
                            const file = e.target.files[0];
                            onChange(file);
                        }}
                    />
                );
            }}
        />
    );
});
