import React, {useState} from "react";
import {Button, Box, Container} from '@mui/material';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';

import firebaseApp from '../firebase.js';

import TextField from '../form/TextField.js';

export default () => {
    const [loading, setLoading] = useState(false);
    const [registering] = useState(false);
    const [error, setError] = useState(null);

    const methods = useForm({
        defaultValues: {
            email: '',
            password: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    const onSubmit = async data => {
        setLoading(true);

        const {email, password} = data;

        try {
            const auth = getAuth(firebaseApp);
            await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }
    };

    // <Button
    //     fullWidth
    //     sx={{mt: 1}}
    //     onClick={() => setRegistering(!registering)}
    //     disabled={loading}
    //     >
    //     {registering ? 'Sign In' : 'Register'}
    // </Button>

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{width: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}
                >
                    <Box sx={{flex: 1, mt: 4, mb: 4, display: 'flex', justifyContent: 'center'}}>
                        <img src="/ttlogo.png" style={{width: 160}} alt="Tourney Time" />
                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            disabled={loading}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address'
                                }
                            }}
                            {...{
                                ...error && {error: true, helperText: error}
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            disabled={loading}
                            rules={{required: true}}
                        />
                        {registering && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                disabled={loading}
                                rules={{required: true}}
                            />
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 1}}
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading || !isValid}
                        >
                            {registering ? 'Register' : 'Sign In'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </FormProvider>
    );
}