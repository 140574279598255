import React, {useState, useEffect, useContext} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import {Backdrop, CircularProgress} from '@mui/material';
import {getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import {get} from 'lodash';

import AppContainer from './containers/AppContainer';

import SignIn from './containers/SignIn';
import NotFound from './containers/NotFound';
import Manage from './containers/Manage';
import Confirmed from './containers/Confirmed';
import Users from './containers/Users';
import User from './containers/User';
import Events from './containers/Events';
import Event from './containers/Event';
import Courses from './containers/Courses';
import Course from './containers/Course';
import CourseRequests from './containers/CourseRequests';
import RequestCourse from './containers/RequestCourse';
// import Download from './containers/Download';
import Featured from './containers/Featured';

import PublicEvent from './containers/PublicEvent';

import {UserContext} from './contexts/userContext';
import firebaseApp from './firebase.js';

const NestedRouter = () => {
    const {userState} = useContext(UserContext);
    const role = get(userState, 'userData.role');

    return (
        <AppContainer>
            <Routes>
                <Route path="events">
                    <Route index element={<Events />} />
                    <Route path="pending" element={<Events view="pending" />} />
                    <Route path="declined" element={<Events view="declined" />} />
                    <Route path="canceled" element={<Events view="canceled" />} />
                    <Route path="upcoming" element={<Events view="upcoming" />} />
                    <Route path="awaiting" element={<Events view="awaiting" />} />
                    <Route path="complete" element={<Events view="complete" />} />
                    <Route path="new" element={<Event isNew />} />
                    <Route path=":id" element={<Event />} />
                    <Route path=":id/:view" element={<Event />} />
                </Route>
                
                <Route path="/" element={<Navigate to="/events" />} />
                
                <Route path="profile" element={<User isProfile />} />

                {role === 'ADMIN' && (
                    <>
                        <Route path="users">
                            <Route index element={<Users />} />
                            <Route path=":id" element={<User />} />
                        </Route>

                        <Route path="courses">
                            <Route index title="Courses" element={<Courses />} />
                            <Route path=":id" element={<Course />} />
                        </Route>

                        <Route path="requests/courses">
                            <Route index title="Course requests" element={<CourseRequests />} />
                        </Route>

                        <Route path="featured" title="Featured" element={<Featured />} />
                    </>   
                )}

                {role === 'COURSE' && (
                    <Route path="course" title="Course Information" element={<Course self />} />
                )}
                
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AppContainer>
    );
};

const MainRouter = () => {
    const [initializationComplete, setInitComplete] = useState(false);
    const {userState, userDispatch} = useContext(UserContext);
    const {userId: authenticated} = userState;
    const db = getFirestore(firebaseApp);
    const auth = getAuth(firebaseApp);

    useEffect(() => {
        onAuthStateChanged(auth, async(user) => {
            if (user) {
                const uid = auth.currentUser.uid;
                const docRef = doc(db, 'users', uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const {role} = data;
                    
                    if (!['ADMIN', 'COURSE'].includes(role)) {
                        await signOut(auth);

                        window.location = 'https://tourneytimeapp.com';
                        return;
                    }

                    if (role === 'COURSE') {
                        const {course: courseId} = data;
                        data.course = {
                            id: courseId,
                            uid: courseId,
                        };

                        try {
                            const courseRef = doc(db, 'courses', courseId);
                            const courseSnap = await getDoc(courseRef);
                            data.course = {
                                ...data.course,
                                ...courseSnap.data()
                            }
                        } catch(e) {
                            //
                        }
                    }

                    userDispatch(
                        {type: 'updateProfile', payload: data}
                    );
                } else {
                    await signOut(auth);
                    
                    window.location = 'https://tourneytimeapp.com';
                    return;
                }
                
                userDispatch({type: 'userId', payload: uid});
                setInitComplete(true);
            } else {
                userDispatch({type: 'signOut'});
                setInitComplete(true);
            }
        });
    }, []);

    if (!initializationComplete) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const reRouteIfAuthenticated = destination => {
        if (authenticated) {
            return <Navigate to="/" />;
        }
    
        return destination;
    };

    const isAdmin = window.location.host.match('admin.tourneytimeapp.com') || window.location.host.match('localhost');

    return (
        <BrowserRouter>
            <Routes>
                {isAdmin && <Route path="/signin" element={reRouteIfAuthenticated(<SignIn />)} />}
                <Route path="/confirmed" element={<Confirmed />} />
                <Route path="/manage" element={<Manage />} />
                {isAdmin && <Route path="/request" element={<RequestCourse />} />}
                {!isAdmin && <Route path="/:id" element={<PublicEvent />} />}
                {isAdmin && <Route path="/s/:id" element={<PublicEvent />} />}

                {!isAdmin && <Route path="*" element={<NotFound />} />}
                {!authenticated && isAdmin && <Route path="*" element={<Navigate to="signin" />} />}
                {authenticated && <Route path="*" element={<NestedRouter />} />}
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;