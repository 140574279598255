import React from 'react';
import {CssBaseline} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {LicenseInfo} from '@mui/x-license-pro';
import ReactGA from 'react-ga4';

import {UserProvider} from './contexts/userContext';
import MainRouter from './MainRouter';

import caLocale from 'date-fns/locale/en-CA';

ReactGA.initialize('G-BGTD4HDY1Z');

LicenseInfo.setLicenseKey('2b99c25adba92266ed8a3f81c52461dbTz01MDIwMyxFPTE2OTM3NTQ1MDExMDksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#20BE68',
            contrastText: '#fff'
        },
        secondary: {
            main: '#3949ab',
            contrastText: '#fff'
        }
    }
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={caLocale}>
                <SnackbarProvider>
                    <CssBaseline />
                    <UserProvider>
                        <MainRouter />
                    </UserProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
