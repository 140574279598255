import React, {useState, useEffect, useCallback, createRef, useContext} from 'react';
import {Box, Button, Grid, Card, CardActionArea, CardMedia, Typography, Divider} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {omit} from 'lodash';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider} from 'react-hook-form';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {getFirestore, doc, getDoc} from 'firebase/firestore';

import firebaseApp from '../../firebase.js';

import {UserContext} from '../../contexts/userContext';
import {EventContext} from '../../contexts/eventContext';

import CourseAutocomplete from '../../form/CourseAutocomplete';
import UserAutocomplete from '../../form/UserAutocomplete.js';
import TextField from '../../form/TextField.js';
import CurrencyField from '../../form/CurrencyField.js';
import SelectField from '../../form/SelectField.js';
import TimePickerField from '../../form/TimePickerField.js';
import CheckboxField from '../../form/CheckboxField.js';
import FileField from '../../form/FileField.js';

import {ApproveButton, DeclineButton, LockdownButton, FeatureButton} from './common';

const functions = getFunctions(firebaseApp);

const createEvent = httpsCallable(functions, 'createEvent');
const updateEvent = httpsCallable(functions, 'updateEvent');
const cancelEvent = httpsCallable(functions, 'cancelEvent');
const deleteEvent = httpsCallable(functions, 'deleteEvent');

export default props => {
    const {isNew} = props;

    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const {id} = useParams();
    const storage = getStorage(firebaseApp);
    const db = getFirestore(firebaseApp);
    const event = useContext(EventContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const imageUploadRef = createRef();
    const {userState} = useContext(UserContext);
    const {userId, userData} = userState;
    const {role, course: userCourse} = userData || {};
    const isCourse = role === 'COURSE';
    const {course: rawCourse} = event || {};
    const [course, setCourse] = useState(isCourse ? userCourse : rawCourse);

    const date = new Date();
    date.setDate(date.getDate() + 31);

    const methods = useForm({
        defaultValues: {
            title: '',
            date,
            prize: 5000 / 100,
            donation: 0,
            isPrePaid: false,
            hostName: '',
            players: 0,
            minPlayers: 4,
            maxPlayers: 50,
            gameType: 'stroke_play',
            status: isCourse ? 'approved' : 'pending',
            isPrivate: false,
            handicapsMandatory: false,
            hasCustomFee: false,
            imageUrl: '',
            imageFile: '',
            dummy: false,
            total: 0,
            ...isCourse ? {
                user: userId,
                course: userCourse
            } : {
                course: ''
            }
        },
        mode: 'onBlur'
    });
    const {handleSubmit, setValue, reset, watch, formState} = methods;
    const {isDirty} = formState;

    const isPrePaid = watch('isPrePaid');
    const players = watch('players') || 0;
    const prize = watch('prize') || 0;
    const donation = watch('donation') || 0;
    const courseId = watch('course') || 0;
    const hasCustomFee = watch('hasCustomFee') || false;
    const courseFee = course && course.fee ? course.fee : 0;
    const totalWithoutFee = parseFloat(prize) + parseFloat(donation) + courseFee;
    const fee = (courseFee + parseFloat(prize)) * 0.05;
    const total = totalWithoutFee + fee;
    const hasCourseFee = course && course.fee;

    const imageUrl = watch('imageUrl');
    const imageFile = watch('imageFile');

    const handleImageUpload = () => {
        imageUploadRef.current.click();
    };

    useEffect(() => {
        if (event) {
            const {course, prize, donation, total, ...rest} = event || {};

            reset({
                ...rest,
                prize: prize / 100,
                donation: donation / 100,
                total: total / 100,
                course: course ? course.id : ''
            });
        }
    }, [event]);

    useEffect(() => {
        if (hasCustomFee && !total) {
            setValue('total', total);
        }
    }, [hasCustomFee]);

    useEffect(() => {
        let isSubscribed = true;

        const fetchCourse = async() => {
            if (isCourse) {
                const {fee, ...restCourse} = userCourse || {};

                setCourse({
                    id: courseId,
                    fee: fee / 100,
                    ...restCourse
                });
            } else {
                const courseRef = doc(db, 'courses', courseId);
                const courseRaw = await getDoc(courseRef);

                const {fee, ...restCourse} = courseRaw.data();

                if (isSubscribed) {
                    setCourse({
                        id: courseId,
                        fee: fee / 100,
                        ...restCourse
                    });
                }
            }
        };

        if (courseId) {
            fetchCourse();
        }

        return () => isSubscribed = false;
    }, [courseId]);

    const onSubmit = async data => {
        let imageUrl;

        data = omit(data, ['imageUrl', 'imageFile', 'createdAt']);
        
        try {
            setSaving(true);

            const {id, prize, donation, date, course, ...rest} = data;

            const totalWithoutFee = parseFloat(prize) + parseFloat(donation) + parseFloat(courseFee);
            const fee = (parseFloat(prize) + courseFee) * 0.05;
            let total = totalWithoutFee + fee;

            if (data.hasCustomFee) {
                total = data.total;
            }
            
            if (isNew) {
                const {data} = await createEvent({
                    ...rest,
                    course: typeof course === 'object' ? course.id : course,
                    date: new Date(date).toString(),
                    prize: prize * 100,
                    donation: donation * 100,
                    total: total * 100,
                    ...imageUrl && {imageUrl}
                });
                const {event: eventUid} = data;

                navigate(`/events/${eventUid}`);
            } else {
                if (imageFile) {
                    const reference = ref(storage, `/events/${data.id}.jpg`);
                    await uploadBytes(reference, imageFile);
                    
                    imageUrl = await getDownloadURL(reference);
                }

                await updateEvent({
                    ...rest,
                    course: typeof course === 'object' ? course.id : course,
                    id,
                    date: new Date(date).toString(),
                    prize: prize * 100,
                    donation: donation * 100,
                    total: total * 100,
                    ...imageUrl && {imageUrl}
                });

                navigate(-1);
            }

            setSaving(false);
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            setSaving(false);
        }
    };

    const onCancel = useCallback(async() => {
        setCanceling(true);

        try {    
            await cancelEvent({
                event: id
            });

            reset({status: 'canceled'});

            enqueueSnackbar('Event canceled', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setCanceling(false);
    });

    const onDelete = useCallback(async() => {
        setDeleting(true);

        try {    
            await deleteEvent({
                event: id
            });

            navigate('/');

            enqueueSnackbar('Event deleted', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setDeleting(false);
    });

    const status = watch('status');

    const gameTypes = [
        {value: 'best_ball', label: 'Best Ball'},
        {value: 'stableford', label: 'Stableford'},
        {value: 'stroke_play', label: 'Stroke Play'},
        // {value: 'team_stroke_play', label: 'Team Stroke'},
        // {value: 'match_play', label: 'Match Play'},
        {value: 'scramble', label: 'Scramble'}
    ];

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                <Grid container spacing={2}>
                    {!isNew && (
                        <Grid item xs={4}>
                            <Card>
                                <CardActionArea
                                    sx={{minHeight: 260, display: 'flex', alignItems: 'center'}}
                                    onClick={handleImageUpload}
                                >
                                    {(imageFile || imageUrl) ? (
                                        <>
                                            <CardMedia
                                                component="img"
                                                height={260}
                                                image={imageUrl ? imageUrl : imageFile ? URL.createObjectURL(imageFile) : null}
                                            />
                                        </>
                                    ) : (
                                        <Typography variant="button">UPLOAD IMAGE</Typography>
                                    )}
                                </CardActionArea>
                                <FileField ref={imageUploadRef} name="imageFile" />
                            </Card>
                        </Grid>
                    )}
                    
                    <Grid item xs={isNew ? 12 : 8}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <TextField
                                sx={{mr: 1, flex: 1}}
                                label="Title"
                                name="title"
                                rules={{required: 'Title is required'}}
                                disabled={saving}
                            />
                            <TimePickerField
                                sx={{width: 160}}
                                label="Time"
                                name="date"
                                disabled={saving}
                                rules={{
                                    required: 'Date is required'
                                }}
                            />
                        </Box>

                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Description"
                            name="body"
                            rows={4}
                            multiline
                            rules={{required: 'Body is required'}}
                            disabled={saving}
                        />

                        <Box sx={{display: 'flex', flexDirection: 'row', mt: 2}}>
                            {isPrePaid ? (
                                <>
                                    <TextField
                                        sx={{mr: 1, flex: 1}}
                                        label="Host Name"
                                        name="hostName"
                                        disabled={saving}
                                        rules={{required: 'Hostname is required'}}
                                    />
                                    <SelectField
                                        sx={{flex: 1}}
                                        label="Game Type"
                                        name="gameType"
                                        disabled={saving}
                                        options={gameTypes}
                                    />
                                </>
                            ) : (
                                <>
                                    <TextField
                                        sx={{mr: 1, flex: 1}}
                                        label="Min. Players"
                                        name="minPlayers"
                                        disabled={saving}
                                        rules={{
                                            required: 'Min. Players is required',
                                            min: {value: 4, message: 'Min. Players must be at least 4'}
                                        }}
                                    />
                                    <TextField
                                        sx={{mr: 1, flex: 1}}
                                        label="Max. Players"
                                        name="maxPlayers"
                                        disabled={saving}
                                        rules={{required: 'Max. Players is required'}}
                                    />
                                    <SelectField
                                        sx={{flex: 1}}
                                        label="Game Type"
                                        name="gameType"
                                        disabled={saving}
                                        options={gameTypes}
                                    />
                                </>
                            )}
                        </Box>

                        <Typography variant="overline" display="block" sx={{mt: 2}}>Payment</Typography>
                        <Divider />

                        <Box sx={{display: 'flex', flexDirection: 'row', mt: 2}}>
                            {isPrePaid ? (
                                <>
                                    <TextField
                                        sx={{mr: 1, flex: 1}}
                                        label="Players"
                                        name="players"
                                        disabled={saving}
                                        rules={{
                                            required: 'Players is required',
                                            min: {value: 2, message: 'Players must be at least 2'}
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <CurrencyField
                                        sx={{mr: 1}}
                                        label="Prize Allowance"
                                        name="prize"
                                        disabled={saving}
                                    />
                                    <CurrencyField
                                        sx={{mr: 1}}
                                        label="Donation Allowance"
                                        name="donation"
                                        disabled={saving}
                                    />
                                </>
                            )}
                            <CurrencyField
                                sx={{mr: 1}}
                                label="Green Fee"
                                name="greenFee"
                                InputProps={{
                                    readOnly: true,
                                    value: course && course.fee ? course.fee : '0'
                                }}
                                {...course && !hasCourseFee && {
                                    error: true
                                }}
                                variant="filled"
                            />
                            <CurrencyField
                                label="Our Fee"
                                name="fee"
                                InputProps={{
                                    readOnly: true,
                                    value: isPrePaid ? (fee * players) : fee
                                }}
                                disabled
                                variant="filled"
                            />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', mt: 2}}>
                            <CheckboxField
                                sx={{mr: 1, flex: 1}}
                                variant="filled"
                                label="Dummy"
                                name="dummy"
                                disabled={saving}
                            />
                            <CheckboxField
                                sx={{mr: 1}}
                                variant="filled"
                                label="Has Custom Total"
                                name="hasCustomFee"
                                disabled={saving}
                            />
                            <CurrencyField
                                label="Total"
                                name="total"
                                {...!hasCustomFee && {
                                    InputProps: {
                                        readOnly: !hasCustomFee,
                                        value: isPrePaid ? (total * players) : total
                                    }
                                }}
                                disabled={!hasCustomFee || saving}
                                variant="filled"
                            />
                        </Box>
                        
                        {!isCourse && (
                            <Box>
                                <Typography variant="overline" display="block" sx={{mt: 2}}>Course & Organizer</Typography>
                                <Divider />

                                <UserAutocomplete
                                    sx={{mt: 2, mb: 2}}
                                    label="Organizer"
                                    name="user"
                                    disabled={saving}
                                    rules={{required: 'Organizer is required'}}
                                />
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                                    <Box sx={{flex: 1}}>
                                        <CourseAutocomplete
                                            label="Course"
                                            name="course"
                                            disabled={saving}
                                            rules={{required: 'Course is required'}}
                                            {...course && !hasCourseFee && {
                                                error: true,
                                                helperText: 'Course does not have a fee'
                                            }}
                                        />
                                    </Box>
                                    {course && !hasCourseFee && (
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            sx={{ml: 1}}
                                            onClick={() => {
                                                window.open(`/courses/${course.id}`, '_blank');
                                            }}
                                        >
                                            Open Course
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        )}
                        
                        <Typography variant="overline" display="block" sx={{mt: 2}}>Other</Typography>
                        <Divider />
                        {!isCourse && (
                            <SelectField
                                fullWidth
                                sx={{mt: 2}}
                                label="Status"
                                name="status"
                                disabled={saving}
                                options={[
                                    {value: 'pending', label: 'Pending'},
                                    {value: 'approved', label: 'Approved'},
                                    {value: 'declined', label: 'Declined'},
                                    {value: 'awaitingPayment', label: 'Awaiting Payment'},
                                    {value: 'locked', label: 'Locked'},
                                    {value: 'ended', label: 'Ended'},
                                    {value: 'canceled', label: 'Canceled'}
                                ]}
                            />
                        )}
                        {!isCourse && (
                            <CheckboxField
                                sx={{mt: 2}}
                                label="Private"
                                name="isPrivate"
                                disabled={saving}
                            />
                        )}
                        <CheckboxField
                            sx={{mt: 2}}
                            label="Handicaps Mandatory"
                            name="handicapsMandatory"
                            disabled={saving}
                        />
                    </Grid>
                </Grid>
                
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
                    {!isNew && isCourse && (
                        <ApproveButton sx={{mr: 1}} eventUid={id} />
                    )}
                    {!isNew && isCourse && (
                        <DeclineButton sx={{mr: 1}} eventUid={id} />
                    )}
                    {!isNew && !isCourse && (
                        <LockdownButton sx={{mr: 1}} eventUid={id} />
                    )}
                    {!isNew && !isCourse && (
                        <LoadingButton
                            variant="text"
                            color="error"
                            size="small"
                            sx={{mr: 1}}
                            loading={deleting}
                            onClick={async () => {
                                enqueueSnackbar('Are you sure you want to delete this event? This cannot be undone', {
                                    variant: 'error',
                                    action: key => {
                                        return (
                                            <>
                                                <Button onClick={() => {
                                                    closeSnackbar(key);
                                                    onDelete();
                                                }}>
                                                    Delete Event
                                                </Button>
                                                <Button onClick={() => closeSnackbar(key)}>
                                                    Dismiss
                                                </Button>
                                            </>
                                        );
                                    }
                                });
                            }}
                        >
                            Delete Event
                        </LoadingButton>
                    )}
                    {!isNew && !isCourse && (
                        <FeatureButton sx={{mr: 1}} eventUid={id} />
                    )}
                    {!isNew && !isCourse && (
                        <LoadingButton
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{mr: 1}}
                            disabled={status === 'canceled'}
                            loading={canceling}
                            onClick={async () => {
                                enqueueSnackbar('Are you sure you want to cancel this event?', {
                                    variant: 'warning',
                                    action: key => {
                                        return (
                                            <>
                                                <Button onClick={() => {
                                                    closeSnackbar(key);
                                                    onCancel();
                                                }}>
                                                    Cancel Event
                                                </Button>
                                                <Button onClick={() => closeSnackbar(key)}>
                                                    Dismiss
                                                </Button>
                                            </>
                                        );
                                    }
                                });
                            }}
                        >
                            Cancel Event
                        </LoadingButton>
                    )}
                    
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!isDirty}
                        onClick={handleSubmit(onSubmit)}
                        loading={saving}
                    >
                        {isNew ? 'Create Event' : 'Save Changes'}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    );
};
