import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Alert, Button} from '@mui/material';
import {getAuth, isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';

import firebaseApp from '../firebase.js';

export default () => {
    const [newDevice, setNewDevice] = useState(false);
    const [complete, setComplete] = useState(false);
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();

    useEffect(() => {
        let email = window.localStorage.getItem('confirmationEmail');
        if (!email) {
            setNewDevice(true);
        } else {
            finishConfirmation(email);
        }
    });

    const finishConfirmation = async confirmedEmail => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            await signInWithEmailLink(auth, confirmedEmail, window.location.href);
            window.localStorage.removeItem('confirmationEmail');
            setComplete(true);
        }
    };

    const NewDeviceCheck = () => (
        <Alert
            sx={{mt: 5, ml: 5, mr: 5}}
            severity="error"
            action={
                <Button color="inherit" size="small" onClick={() => navigate('/signin')}>
                    TRY AGAIN
                </Button>
            }
        >
            Please verify your account from the same device and browser
        </Alert>
    );

    const Confirmed = () => (
        <Alert sx={{mt: 5, ml: 5, mr: 5}} severity="success">You are now signed in! Redirecting...</Alert>
    );

    if (newDevice === true && complete !== true && !auth.currentUser) {
        return <NewDeviceCheck />;
    }

    setTimeout(() => {
        navigate('/');
    }, 1500);

    return <Confirmed />;
};
