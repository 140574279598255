import React, {useState, useEffect, useCallback, useContext} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {get} from 'lodash';
import moment from 'moment';
import {getFirestore, doc, getDoc, collection, query, onSnapshot, orderBy} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {TextField, InputAdornment, IconButton, CircularProgress} from '@mui/material';
import {Send} from '@mui/icons-material';

import firebaseApp from '../../firebase.js';
import {UserContext} from '../../contexts/userContext';

import {DeleteButton} from './common';

const functions = getFunctions(firebaseApp);
const messageEvent = httpsCallable(functions, 'messageEvent');
const deleteMessage = httpsCallable(functions, 'deleteMessage');

const agoDateFormatter = params => {
    const {value} = params;
    if (!value) {
        return '-';
    }

    return moment(value.toDate ? value.toDate() : value).fromNow();
};

const db = getFirestore(firebaseApp);

export default () => {
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const {id: eventUid} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const {userState} = useContext(UserContext);
    const {userData} = userState;
    const {displayName, role} = userData || {};

    const isCourse = role === 'COURSE';

    const onDelete = useCallback(messageUid => {
        return async() => {
            await deleteMessage({
                event: eventUid,
                message: messageUid 
            });
        };
    }, [eventUid, messages]);

    const columns = [
        {field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: agoDateFormatter},
        {field: 'user.displayName', headerName: 'User', flex: 1, valueGetter: params => {
            return get(params, 'row.user.displayName'); 
        }},
        {field: 'text', headerName: 'Text', flex: 4},
        {
            field: '',
            headerName: '',
            type: 'actions',
            renderCell: params => {
                const {row} = params;
                const {uid} = row;
    
                return <DeleteButton onDelete={onDelete(uid)} />;
            },
            disableClickEventBubbling: true
        }
    ];

    const handleMessages = async messagesRaw => {
        const messages = messagesRaw.docs.map(message => {
            const {id} = message;
            return {
                id,
                uid: id,
                ...message.data()
            };
        });

        // TODO this should be done with a single query
        const users = {};
        for (const message of messages) {
            const {user} = message;
            if (user === 'system') {
                users[user] = {
                    displayName: 'System'
                };
            } else {
                const userRef = doc(db, 'users', user);
                const userSnap = await getDoc(userRef);
                users[user] = userSnap.data();
            }
        }

        setMessages(messages.map((message, index) => {
            const {id, user, ...rest} = message;

            return {
                id: id || `message-${index}`,
                user: users[user],
                ...rest
            };
        }));

        setLoading(false);
    };

    useEffect(() => {
        const messagesRef = collection(db, 'events', eventUid, 'messages');
        const messagesQuery = query(messagesRef, orderBy('createdAt'));
        const unsubscribe = onSnapshot(messagesQuery, messagesRaw => {
            handleMessages(messagesRaw);
        });

        return () => unsubscribe();
    }, []);

    const onSend = async () => {
        if (!text || sending) {
            return;
        }

        setSending(true);

        try {
            await messageEvent({
                event: eventUid,
                text,
                system: !isCourse
            });

            enqueueSnackbar('Message sent', {variant: 'success'});
            setText('');
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSending(false);
    };

    return (
        <div>
            <DataGrid
                loading={loading}
                autoHeight
                hideFooter
                rows={messages}
                columns={columns}
                disableSelectionOnClick
            />

            <TextField 
                sx={{mt: 1}}
                placeholder={isCourse ? `Message as ${displayName}...` : 'Message as system...'}
                fullWidth
                value={text}
                disabled={sending}
                onChange={e => setText(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end" onClick={onSend} disabled={sending}>
                                {sending ? <CircularProgress color="primary" /> : <Send />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};