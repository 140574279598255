import React, {useState, useEffect, useCallback} from 'react';
import {Container, Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {getFirestore, collection, getDocs, onSnapshot, deleteDoc, query, where, limit, orderBy, setDoc, doc, getDoc, documentId} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useSnackbar} from 'notistack';

import firebaseApp from '../firebase.js';

import EventAutocomplete from '../form/EventAutocomplete';

import {DeleteButton} from './event/common';

const SearchForm = () => {
    const [saving, setSaving] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const db = getFirestore(firebaseApp);
    const methods = useForm({
        defaultValues: {
            event: null
        },
        mode: 'onBlur'
    });
    const {handleSubmit, watch} = methods;

    const event = watch('event');

    const onSubmit = useCallback(async data => {
        const {event} = data;

        try {
            setSaving(true);

            const ref = doc(db, 'featuredEvents', event);
            
            await setDoc(ref, {
                createdAt: new Date(),
                active: true
            });

            enqueueSnackbar('Event Featured', {variant: 'success'});
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    });

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 2, mb: 3}}>
                <EventAutocomplete
                    label="Find Event to Feature"
                    name="event"
                    disabled={saving}
                    fullWidth
                />

                {!!event && (
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        loading={saving}
                        sx={{mt: 1}}
                    >
                        Feature Event
                    </LoadingButton>
                )}
            </Box>
        </FormProvider>
    );
};

export default () => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();

    useEffect(() => {
        const collectionRef = collection(db, 'featuredEvents');
        const q = query(
            collectionRef,
            where('active', '==', true),
            orderBy('createdAt', 'desc'),
            limit(15)
        );

        const unsubscribe = onSnapshot(q, async raw => {
            const eventIds = [];
            raw.forEach(doc => eventIds.push(doc.id));

            const docs = [];
            if (eventIds.length) {
                const eventsRef = collection(db, 'events');
                const eventsQuery = query(eventsRef, where(documentId(), 'in', eventIds));
                const eventsRaw = await getDocs(eventsQuery);

                eventsRaw.forEach(doc => {
                    docs.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
            }
            
            setEvents(docs);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const onDelete = useCallback(uid => {
        return async() => {
            const ref = doc(db, 'featuredEvents', uid);
            const existing = await getDoc(ref);

            if (!existing.exists) {
                throw new Error('Event is not featured');
            }

            await deleteDoc(ref);
        };
    }, []);

    const columns = [
        // {field: 'imageUrl', headerName: '', width: 70, renderCell: renderImageCell},
        {field: 'title', headerName: 'Title', flex: 1},
        {
            field: '',
            headerName: '',
            type: 'actions',
            renderCell: params => {
                const {row} = params;
                const {id} = row;
    
                return <DeleteButton label="Remove" onDelete={onDelete(id)} />;
            },
            disableClickEventBubbling: true
        }
    ];

    return (
        <Container>
            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <SearchForm />
                    
                    <DataGrid
                        loading={loading}
                        autoHeight
                        rowHeight={70}
                        rows={events}
                        columns={columns}
                        rowCount={events.length}
                        hideFooter
                        disableSelectionOnClick
                        rowsPerPageOptions={[1]}
                        onCellClick={params => {
                            const {row, field} = params;

                            if (field) {
                                navigate(`/events/${row.id}`);
                            }
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};
