import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';

import UserAutocomplete from '../form/UserAutocomplete';

export default props => {
    const {title, body, buttonText, open, onClose, onSelect} = props;

    const methods = useForm({
        defaultValues: {
            user: null
        },
        mode: 'onBlur'
    });
    const {handleSubmit} = methods;

    const onSubmit = data => {
        const {user} = data;
        onSelect(user);
    };

    return (
        <FormProvider {...methods}>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>{title || 'Invite User'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {body || 'Select a user to invite to this event'}:
                    </DialogContentText>

                    <UserAutocomplete
                        fullWidth
                        sx={{mt: 2}}
                        name="user"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit(onSubmit)}>{buttonText || 'Invite'}</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};