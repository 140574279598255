import React, {useState, useEffect, useCallback} from 'react';
import {Container, Box, Button, Card, CardContent, Typography, Avatar} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {LoadingButton} from '@mui/lab';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {chunk} from 'lodash';
import {getFirestore, collection, getDocs, query, orderBy, limit, where, endBefore, limitToLast, startAfter, documentId} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import algoliasearch from 'algoliasearch';

import firebaseApp from '../firebase.js';

import TextField from '../form/TextField';

export default () => {
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    const [courses, setCourses] = useState([]);
    const [rowCount, setRowCount] = useState(Number.MAX_VALUE);
    const [first, setFirst] = useState(null);
    const [last, setLast] = useState(null);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0
    });

    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: {
            search: null,
            city: null
        },
        mode: 'onBlur'
    });
    const {handleSubmit} = methods;

    const handlePaginationModelChange = useCallback((newPaginationModel) => {
        if (newPaginationModel.page < paginationModel.page) {
            setLast(null);
        } else {
            setFirst(null);
        }

        setPaginationModel(newPaginationModel);
    });

    const fetchCourses = useCallback(async (params) => {
        const {search, city} = params || {};

        setLoading(true);
        setSearching(true);

        const collectionRef = collection(db, 'courses');
        let q = query(collectionRef);

        let docs = [];

        const {pageSize, page} = paginationModel;

        if (search || city) {
            const client = algoliasearch('V0HTM0BKX4', 'ee12d8e047b5ed41f4a1820f671d14f2');
            const index = client.initIndex('coursesLocationIndex');

            const result = await index.search(search || '', {
                hitsPerPage: 20,
                page,
                ...city && {facetFilters: [[`city:${city}`]]}
            });
            const {hits, nbHits} = result;

            if (hits.length) {
                const batches = [];
                const chunks = chunk(hits.map(hit => hit.objectID), 10);

                for (const ids of chunks) {
                    batches.push(new Promise(resolve => {
                        const fetch = async() => {
                            const batchQuery = query(q, where(documentId(), 'in', ids));
                            const querySnapshot = await getDocs(batchQuery);
    
                            const batchDocs = [];
                            querySnapshot.forEach(doc => {
                                batchDocs.push({
                                    id: doc.id,
                                    ...doc.data()
                                });
                            });

                            resolve(batchDocs);
                        };
                        
                        fetch();
                    }));
                }
                
                const result = await Promise.all(batches);
                docs = result.flat();

                setRowCount(nbHits);
            }
        } else {
            q = query(
                q,
                orderBy('country', 'asc'),
                orderBy('name', 'asc')
            );

            if (last) {
                q = query(q, startAfter(last));
            }

            let limitedQuery;
            if (page > 0) {
                if (last) {
                    limitedQuery = query(q, startAfter(last), limit(pageSize));
                } else if (first) {
                    limitedQuery = query(q, endBefore(first), limitToLast(pageSize));
                }
            } else {
                limitedQuery = query(q, limit(pageSize));
            }

            const querySnapshot = await getDocs(limitedQuery);
            const length = querySnapshot.docs.length;
            setFirst(querySnapshot.docs[0]);

            if (length > 0) {
                setLast(querySnapshot.docs[length - 1]);
            }

            if (length < pageSize) {
                setRowCount((pageSize * page) + length);
            } else {
                setRowCount(Number.MAX_VALUE);
            }

            querySnapshot.forEach(doc => {
                docs.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
        }

        setCourses(docs);
        setSearching(false);
        setLoading(false);
    }, [last]); 

    useEffect(() => {
        fetchCourses();
    }, [paginationModel]);

    const renderImageCell = useCallback(params => {
        const {value, row} = params;
        const {name} = row;

        return (
            <Avatar src={value} alt={name}>
                <GolfCourseIcon />
            </Avatar>
        );
    });

    const columns = [
        {field: 'imageUrl', headerName: '', width: 60, renderCell: renderImageCell},
        {field: 'name', headerName: 'Name', flex: 3},
        {field: 'city', headerName: 'City', flex: 2},
        {field: 'state', headerName: 'State', flex: 2},
        {field: 'country', headerName: 'Country', flex: 1}
    ];

    return (
        <Container>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                <Button component={RouterLink} variant="contained" to="/courses/new">Add Course</Button>
            </Box>

            <Card variant="outlined" sx={{mb: 2}}>
                <CardContent>
                    <FormProvider {...methods}>
                        <Box component="form" onSubmit={handleSubmit(fetchCourses)}>
                            <Typography variant="h6" component="div">
                                Filter
                            </Typography>

                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Name"
                                    placeholder="Black Mountain"
                                    name="search"
                                    sx={{mr: 1}}
                                    disabled={searching}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="City"
                                    placeholder="Kelowna"
                                    name="city"
                                    disabled={searching}
                                />
                            </Box>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
                            <LoadingButton variant="contained" color="primary" loading={searching} onClick={handleSubmit(fetchCourses)}>Search</LoadingButton>
                        </Box>
                    </FormProvider>
                </CardContent>
            </Card>


            <div style={{width: '100%'}}>
                <DataGrid
                sx={{width: '100%'}}
                    loading={loading}
                    autoHeight
                    rowHeight={70}
                    rows={courses}
                    columns={columns}
                    rowCount={rowCount}
                    disableSelectionOnClick
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: ({from, to, count}) => `${from} - ${to} of ${count === Number.MAX_VALUE ? "many" : count}`
                        }
                    }}
                    onRowClick={params => {
                        const {row} = params;

                        navigate(`/courses/${row.id}`);
                    }}
                />
            </div>
        </Container>
    );
};
