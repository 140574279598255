import React, {useState, useEffect} from 'react';
import {DataGrid, gridClasses} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {get} from 'lodash';
import {Paper, Typography, Divider} from '@mui/material';
import {formatMoney} from 'accounting';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';

import firebaseApp from '../../firebase.js';

export default () => {
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [data, setData] = useState({});
    const functions = getFunctions(firebaseApp);
    const getEventMoney = httpsCallable(functions, 'getEventMoney');
    const {enqueueSnackbar} = useSnackbar();

    const {id: eventUid} = useParams();

    const columns = [
        {field: 'id', headerName: '#', flex: 1},
        {field: 'amount', headerName: '$', flex: 1, valueGetter: ({value}) => formatMoney(value / 100)},
        {field: 'user.displayName', headerName: 'User', flex: 1, valueGetter: params => {
            return get(params, 'row.user.displayName'); 
        }},
        {field: 'createdAt', headerName: 'Date', flex: 1},
        {field: 'status', headerName: 'Status', flex: 1}
    ];

    useEffect(() => {
        async function fetchUsers() {
            try {
                const {data} = await getEventMoney({event: eventUid});
                const {payments, ...rest} = data;

                console.log(payments);
                console.log(data);

                setData(rest);
                setPayments(payments);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            setLoading(false);
        }

        fetchUsers();
    }, [eventUid]);

    return (
        <div style={{width: '100%'}}>
            <Paper
                sx={{
                    p: 2,
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="subtitle2">Gross</Typography>
                <Typography component="p" variant="h4">{formatMoney(data.grossTotal / 100)}</Typography>

                <Typography variant="subtitle2">Course Profit</Typography>
                <Typography component="p" variant="h4">{formatMoney(data.courseTotal / 100)}</Typography>

                <Divider sx={{mt: 2, mb: 2}} />

                <Typography variant="subtitle2">Refunded</Typography>
                <Typography component="p" variant="h4">{formatMoney(data.refundedTotal / 100)}</Typography>

                <Divider sx={{mt: 2, mb: 2}} />

                <Typography variant="subtitle2">Our Profit</Typography>
                <Typography component="p" variant="h4">{formatMoney(data.netTotal / 100)}</Typography>
            </Paper>

            <Typography variant="h6" sx={{mb: 2}}>Payments</Typography>

            <DataGrid
                loading={loading}
                autoHeight
                getRowHeight={() => 'auto'}
                rows={payments}
                columns={columns}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                }}
                disableSelectionOnClick
            />
        </div>
    );
};