import React, {useState, useEffect, useContext} from 'react';
import {Container, Box, Tabs, Tab, CircularProgress} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import firebaseApp from '../firebase.js';

import Form from './event/Form';
import Attendees from './event/Attendees';
import Singles from './event/Singles';
import Groups from './event/Groups';
import Messages from './event/Messages';
import Money from './event/Money';

import {UserContext} from '../contexts/userContext';
import {EventContext} from '../contexts/eventContext';

const TabPanel = props => {
    const {hidden, children} = props;

    return (
        <Box sx={{pt: 1, pb: 2}} style={{display: hidden ? 'none' : 'block', width: '100%'}}>
            {children}
        </Box>
    );
};

export default props => {
    const {isNew} = props;
    const {id, view} = useParams();
    const [loading, setLoading] = useState(true && !isNew);
    const navigate = useNavigate();
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const [event, setEvent] = useState(null);

    const {userState} = useContext(UserContext);
    const {userData} = userState;
    const {role} = userData || {};
    const isCourse = role === 'COURSE';

    const tabs = [
        {label: 'Details', path: '', component: <Form isNew={isNew} />},
        {label: 'Attendees', path: 'attendees', component: <Attendees />},
        {label: 'Unassigned', path: 'unassigned', component: <Singles />},
        {label: 'Tee Sheet', path: 'teesheet', component: <Groups />},
        {label: 'Messages', path: 'messages', component: <Messages />}
    ];

    if (!isCourse) {
        tabs.push({label: 'Money', path: 'money', component: <Money />});
    }

    const activeTabIndex = view ? tabs.findIndex(tab => tab.path === view) : 0;

    const eventRef = !isNew && doc(db, 'events', id);

    useEffect(() => {
        let isSubscribed = true;

        async function fetchEvent() {
            const eventRaw = await getDoc(eventRef);
            if (!eventRaw.exists()) {
                enqueueSnackbar('Event not found', {variant: 'error'});
                navigate('/');
                return;
            }

            const {date, createdAt, course: courseId, ...rest} = eventRaw.data();
            let course;
            if (courseId) {
                const courseRef = doc(db, 'courses', courseId);
                const courseRaw = await getDoc(courseRef);

                const {fee, ...restCourse} = courseRaw.data();

                course = {
                    id: courseId,
                    fee: fee / 100,
                    ...restCourse
                };
            }

            if (isSubscribed) {
                setEvent({
                    id,
                    ...rest,
                    date: date.toDate(),
                    createdAt: createdAt.toDate(),
                    course
                });

                setLoading(false);
            }
        };

        if (!isNew && isSubscribed) {
            fetchEvent();
        }

        return () => isSubscribed = false;
    }, []);

    return (
        <EventContext.Provider value={event}>
            <Container>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={activeTabIndex} onChange={(e, index) => navigate(`/events/${id}/${tabs[index].path}`)}>
                        {tabs.map((tab, index) => {
                            if (!isNew || index === 0) {
                                return (
                                    <Tab key={`tab-label-${index}`} label={tab.label} />
                                );
                            }

                            return null;
                        })}
                    </Tabs>
                </Box>

                {loading ? (
                    <Box sx={{textAlign: 'center', pt: 2, pb: 3}}>
                        <CircularProgress color="primary" />
                    </Box>
                ) : (!isNew || activeTabIndex === 0) && tabs.map((tab, index) => (
                    <TabPanel key={`tab-${index}`} hidden={activeTabIndex !== index}>
                        {activeTabIndex === index && tab.component}
                    </TabPanel>
                ))}
            </Container>
        </EventContext.Provider>
    );
};