import React from 'react';
import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';
import {MobileDateTimePicker} from '@mui/x-date-pickers';

export default props => {
    const {name, label, rules, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => (
                <MobileDateTimePicker
                    {...field}
                    label={label}
                    renderInput={(params) => (
                        <TextField
                            {...rest}
                            error={!!error}
                            helperText={error ? error.message : null}
                            {...params}
                        />
                    )}
                />
            )}
        />
    );
};
