import React, {useState, useCallback} from 'react';
import {useSnackbar} from 'notistack';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getAuth} from 'firebase/auth';
import {LoadingButton} from '@mui/lab';
import {Button, Chip} from '@mui/material';
import axios from 'axios';
import {getFirestore, setDoc, doc} from 'firebase/firestore';

import firebaseApp from '../../firebase.js';

import Prompt from '../../modals/Prompt';
import GroupSelection from '../../modals/GroupSelection';

const functions = getFunctions(firebaseApp);
const approveEvent = httpsCallable(functions, 'approveEvent');
const declineEvent = httpsCallable(functions, 'declineEvent');
const addGroupToEvent = httpsCallable(functions, 'addGroupToEvent');
const moveSingleToGroup = httpsCallable(functions, 'moveSingleToGroup');
const switchGroup = httpsCallable(functions, 'switchGroup');
const moveToSingle = httpsCallable(functions, 'moveToSingle');
const lockdownEvent = httpsCallable(functions, 'lockdownEvent');

export const ApproveButton = props => {
    const {uid, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onClick = useCallback(async() => {
        setLoading(true);

        try {
            await approveEvent({
                event: uid
            });

            enqueueSnackbar('Event approved', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
            onClick={onClick}
            {...rest}
        >
            Approve
        </LoadingButton>
    );
};

export const DeclineButton = props => {
    const {uid, ...rest} = props;
    const [promptOpen, setPromptOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState(false);

    const onDecline = useCallback(async(reason) => {
        setLoading(true);
        setPromptOpen(false);

        try {
            await declineEvent({
                event: uid,
                reason
            });

            enqueueSnackbar('Event declined', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <>
            <Prompt
                title="Decline Event"
                description={(
                    <div>
                        <div>Please enter a reason for declining this event. The user will recieve the following notification:</div>
                        <Chip sx={{marginTop: 1}} label={`Your event has been declined because${reason ? ` ${reason}` : '...'}`} />
                    </div>
                )}
                saveText="Decline"
                open={promptOpen}
                onClose={() => setPromptOpen(false)}
                onSelect={onDecline}
                onTextChange={text => setReason(text)}
            />

            <LoadingButton
                variant="contained"
                color="error"
                size="small"
                loading={loading}
                onClick={() => setPromptOpen(true)}
                {...rest}
            >
                Decline
            </LoadingButton>
        </>
    );
};

export const DeleteButton = props => {
    const {label, onDelete, ...rest} = props;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onConfirmedClick = useCallback(async() => {
        setLoading(true);

        try {
            await onDelete();

            enqueueSnackbar('Deleted', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});

            setLoading(false);
        }
    }, []);

    const onClick = useCallback(async() => {
        enqueueSnackbar('Are you sure you want to delete this?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onConfirmedClick();
                        }}>
                            Yes
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, []);

    return (
        <LoadingButton
            variant="contained"
            color="error"
            size="small"
            loading={loading}
            onClick={onClick}
            {...rest}
        >
            {label || 'Delete'}
        </LoadingButton>
    );
};

export const LockdownButton = props => {
    const {eventUid, ...rest} = props;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onConfirmedClick = useCallback(async() => {
        setLoading(true);

        try {
            await lockdownEvent({event: eventUid});

            enqueueSnackbar('Locked Down', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    const onClick = useCallback(async() => {
        enqueueSnackbar('Are you sure you want to lockdown this event?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onConfirmedClick();
                        }}>
                            Lockdown
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, []);

    return (
        <LoadingButton
            color="error"
            size="small"
            loading={loading}
            onClick={onClick}
            {...rest}
        >
            Lockdown Event
        </LoadingButton>
    );
};

export const AddGroupToEventButton = props => {
    const {uid, onSuccess, ...rest} = props;
    const [promptOpen, setPromptOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onAdd = useCallback(async(name) => {
        setLoading(true);
        setPromptOpen(false);

        try {
            await addGroupToEvent({
                event: uid,
                name
            });

            enqueueSnackbar('Added Group', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);

        if (onSuccess) {
            onSuccess();
        }
    }, []);

    return (
        <>
            <Prompt
                title="Add Group"
                description="Please enter a name for this group:"
                saveText="Add"
                open={promptOpen}
                onClose={() => setPromptOpen(false)}
                onSelect={onAdd}
            />

            <LoadingButton
                variant="contained"
                size="small"
                loading={loading}
                onClick={() => setPromptOpen(true)}
                {...rest}
            >
                Add Group
            </LoadingButton>
        </>
    );
};

export const ExportTeeSheetButton = props => {
    const {uid, onSuccess, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onExport = useCallback(async() => {
        setLoading(true);

        try {
            const token = await getAuth(firebaseApp).currentUser.getIdToken();

            const response = await axios({
                url: 'https://us-central1-tourney-time.cloudfunctions.net/exportEventGroups',
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    event: uid
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Tee Sheet.xlsx');
            document.body.appendChild(link);
            link.click();

            enqueueSnackbar('Starting Download...', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);

        if (onSuccess) {
            onSuccess();
        }
    }, []);

    return (
        <>
            <LoadingButton
                variant="contained"
                size="small"
                loading={loading}
                onClick={() => onExport()}
                {...rest}
            >
                Export Tee Sheet
            </LoadingButton>
        </>
    );
};

export const MoveSingleToGroupButton = props => {
    const {eventUid, userUid} = props;
    const [promptOpen, setPromptOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onMoveToGroup = useCallback(async(group) => {
        setLoading(true);
        setPromptOpen(false);

        const {uid} = group;

        try {
            await moveSingleToGroup({
                event: eventUid,
                user: userUid,
                group: uid
            });

            enqueueSnackbar('Moved to Group', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <>
            <GroupSelection
                eventUid={eventUid}
                open={promptOpen}
                onClose={() => setPromptOpen(false)}
                onSelect={onMoveToGroup}
            />

            <LoadingButton
                variant="contained"
                size="small"
                loading={loading}
                onClick={() => setPromptOpen(true)}
            >
                Move to Group
            </LoadingButton>
        </>
    );
};

export const SwitchGroupButton = props => {
    const {eventUid, userUid, onSuccess, ...rest} = props;
    const [promptOpen, setPromptOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onMoveToGroup = useCallback(async(group) => {
        setLoading(true);
        setPromptOpen(false);

        const {uid} = group;

        try {
            await switchGroup({
                event: eventUid,
                user: userUid,
                group: uid
            });

            enqueueSnackbar('Moved to Group', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);

        if (onSuccess) {
            onSuccess();
        }
    }, []);

    return (
        <>
            <GroupSelection
                eventUid={eventUid}
                open={promptOpen}
                onClose={() => setPromptOpen(false)}
                onSelect={onMoveToGroup}
            />

            <LoadingButton
                size="small"
                loading={loading}
                onClick={() => setPromptOpen(true)}
                {...rest}
            >
                Switch Group
            </LoadingButton>
        </>
    );
};

export const MoveToSingleButton = props => {
    const {eventUid, userUid, onSuccess, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onMoveToSingle = useCallback(async() => {
        setLoading(true);

        try {
            await moveToSingle({
                event: eventUid,
                user: userUid
            });

            enqueueSnackbar('Moved to Single', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);

        if (onSuccess) {
            onSuccess();
        }
    }, []);

    return (
        <>
            <LoadingButton
                size="small"
                loading={loading}
                onClick={onMoveToSingle}
                {...rest}
            >
                Switch to Single
            </LoadingButton>
        </>
    );
};

export const FeatureButton = props => {
    const {eventUid, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const db = getFirestore(firebaseApp);

    const onFeature = useCallback(async() => {
        setLoading(true);

        try {
            const ref = doc(db, 'featuredEvents', eventUid);
            await setDoc(ref, {
                createdAt: new Date(),
                active: true
            });

            enqueueSnackbar('Featured Event', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <>
            <LoadingButton
                size="small"
                loading={loading}
                onClick={onFeature}
                {...rest}
            >
                Feature Event
            </LoadingButton>
        </>
    );
};