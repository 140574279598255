import React, {useState, useEffect, useCallback} from 'react';
import {Container, Button, Box, Avatar} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import moment from 'moment';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import firebaseApp from '../firebase.js';

const stringToColor = string => {
    if (!string) {
        return;
    }

    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
};

const stringToInitials = name => {
    if (!name) {
        return;
    }

    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
};

export default () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const functions = getFunctions(firebaseApp);
    const getUsers = httpsCallable(functions, 'getUsers');

    useEffect(() => {
        async function fetchUsers() {
            try {
                const {data} = await getUsers();
                setUsers(data);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            setLoading(false);
        }

        fetchUsers();
    }, []);

    const dateFormatter = params => {
        const {value} = params;
        if (!value) {
            return '-';
        }

        return moment(value && value.toDate ? value.toDate() : value).fromNow();
    };

    const renderImageCell = useCallback(params => {
        const {value, row} = params;
        const {displayName, course} = row;

        return (
            <Avatar
                src={value}
                alt={displayName}
                sx={{
                    bgcolor: stringToColor(displayName)
                }}
            >
                {course ? (
                    <GolfCourseIcon />
                ) : stringToInitials(displayName)}
            </Avatar>
        );
    });

    const columns = [
        {field: 'imageUrl', headerName: '', width: 60, renderCell: renderImageCell},
        {field: 'email', headerName: 'Email', flex: 2},
        {field: 'displayName', headerName: 'Name', flex: 1},
        {field: 'emailVerified', headerName: 'Verified', width: 100, valueFormatter: params => params.value ? 'Yes' : 'No'},
        {field: 'creationTime', headerName: 'Created', flex: 1, valueFormatter: dateFormatter},
        {field: 'lastSignInTime', headerName: 'Last Sign In', flex: 1, valueFormatter: dateFormatter}
    ];

    return (
        <Container>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                <Button component={RouterLink} variant="contained" to="/users/new">Add User</Button>
            </Box>

            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <DataGrid
                        loading={loading}
                        autoHeight
                        rows={users}
                        columns={columns}
                        disableSelectionOnClick
                        onRowClick={params => {
                            const {row} = params;

                            navigate(`/users/${row.id}`);
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};
