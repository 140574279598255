import {getFirestore, getDocs, collection, query, where, limit, documentId} from 'firebase/firestore';
import {chunk, compact} from 'lodash';

import firebaseApp from '../firebase.js';

const db = getFirestore(firebaseApp);

export const eventsRef = collection(db, 'events');
export const usersRef = collection(db, 'users');
export const coursesRef = collection(db, 'courses');

export const fetchDocumentsWithIds = async(ref, ids, returnObject) => {
    const data = returnObject ? {} : [];

    if (!ids || !ids.length) {
        return data;
    }

    const chunks = chunk(ids, 10);

    for (const chunk of chunks) {
        const chunkQuery = query(ref, where(documentId(), 'in', chunk), limit(10));
        const raw = await getDocs(chunkQuery);

        if (raw) {
            raw.forEach(item => {
                const {id} = item;
                const base = {
                    id,
                    uid: id,
                    ...item.data()
                };
                
                if (returnObject) {
                    data[id] = base;
                } else {
                    data.push(base);
                }
            });
        }
    }

    return data;
};

export const populateCourses = async (raw) => {
    if (!raw || !raw.length) {
        return [];
    }
    
    const courseIds = compact(raw.map(item => item.course));
    const courses = await fetchDocumentsWithIds(coursesRef, courseIds, true);

    return raw.map(item => {
        const {course: courseId, ...rest} = item;

        return {
            ...rest,
            course: courses[courseId]
        };
    });
};

export const populateEvents = async (raw, currentUser) => {
    let events = [];

    if (raw) {
        raw.forEach(event => {
            if (event.data) {
                events.push({
                    ...event.data(),
                    id: event.id,
                    uid: event.id,
                });
            } else {
                events.push(event);
            }
        });
    }

    if (events.length) {
        const userIds = compact(events.map(event => event.user));
        const users = await fetchDocumentsWithIds(usersRef, userIds, true);

        const courseIds = compact(events.map(event => event.course));
        const courses = await fetchDocumentsWithIds(coursesRef, courseIds, true);

        events = await Promise.all(events.map(async event => {
            const {uid, course: courseId, user: userId, ...rest} = event;

            const attendeesRef = collection(db, 'events', uid, 'attendees');
            const attendeesQuery = query(attendeesRef);
            const attendeesRaw = await getDocs(attendeesQuery);
            const attendees = attendeesRaw.docs.map(attendee => attendee.data());

            let attending = false;
            if (currentUser) {
                attending = !!attendees.find(attendee => attendee.user === currentUser.uid);
            }

            return {
                ...rest,
                course: courses[courseId],
                user: users[userId],
                attendees,
                attending,
                ...currentUser && {own: currentUser ? currentUser.uid === event.user : false},
                uid
            };
        }));
    }

    return events;
};
