import React, {useContext, useState} from 'react';
import {
    AppBar,
    Box,
    Toolbar,
    Drawer,
    Typography,
    IconButton,
    MenuItem,
    Menu,
    Breadcrumbs,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Avatar,
    Divider
} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {get} from 'lodash';
import {Link as RouterLink, useNavigate, useLocation, NavLink} from 'react-router-dom';
import {
    Menu as MenuIcon,
    AccountCircle as AccountCircleIcon,
    GolfCourse as GolfCourseIcon,
    EventNote as EventNoteIcon,
    EventAvailable as EventAvailableIcon,
    People as PeopleIcon,
    Logout as LogoutIcon,
    WavingHand as WavingHandIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import {getAuth, signOut} from 'firebase/auth';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import firebaseApp from '../firebase.js';
import {UserContext} from '../contexts/userContext';

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const DrawerWidth = 240;

const openedMixin = theme => ({
    width: DrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const StyledAppBar = styled(AppBar, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: DrawerWidth,
        width: `calc(100% - ${DrawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const StyledDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    width: DrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const HeaderBreadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{m: 3}}>
            {breadcrumbs.map((item, index) => {
                const {breadcrumb, key} = item;
                const last = breadcrumbs.length - 1 === index;

                return last ? (
                    <Typography color="textPrimary" key={key}>
                        {breadcrumb}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={key} key={key}>
                        {breadcrumb}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
};

const AppContainer = props => {
    const {children} = props;
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const {userState, userDispatch} = useContext(UserContext);
    const {userData} = userState;
    const {course, role} = userData || {};
    let {displayName, imageUrl} = userData || {};
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    if (role === 'COURSE') {
        ({name: displayName} = course || {});
        imageUrl = get(userData, 'course.imageUrl');
    }

    const pushTo = path => {
        handleClose();
        
        if (location.pathname !== path) {
            navigate(path);
        }
    };

    const handleProfile = event => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setProfileAnchorEl(null);
    };

    const onSignOut = async() => {
        handleClose();

        const auth = getAuth(firebaseApp);
        await signOut(auth);

        userDispatch({
            type: 'userId',
            payload: null
        });
    };

    const isAdmin = role === 'ADMIN';
    const isCourse = role === 'COURSE';

    const menuItems = [
        {label: 'Events', to: '/events', icon: <EventNoteIcon />}
    ];

    if (isAdmin) {
        menuItems.push({label: 'Featured Events', to: '/featured', icon: <EventAvailableIcon />});
        menuItems.push({label: 'Courses', to: '/courses', icon: <GolfCourseIcon />});
        menuItems.push({label: 'Course Requests', to: '/requests/courses', icon: <WavingHandIcon />});
        menuItems.push({label: 'Users', to: '/users', icon: <PeopleIcon />});
    }

    if (isCourse) {
        menuItems.push({label: 'Course Information', to: '/course', icon: <GolfCourseIcon />});
    }

    return (
        <Box sx={{display: 'flex'}}>
            
            <StyledAppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{mr: 2}}
                        onClick={() => setOpen(!open)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        TourneyTime
                    </Typography>
                    {userState && (
                        <>
                            <Typography component="div">{displayName}</Typography>
                            
                            <IconButton
                                onClick={handleProfile}
                                sx={{ml: 1}}
                                size="small"
                                aria-controls={profileAnchorEl ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={profileAnchorEl ? 'true' : undefined}
                            >
                                <Avatar sx={{width: 32, height: 32}} src={imageUrl} alt={displayName}>
                                    <AccountCircleIcon />
                                </Avatar>
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={profileAnchorEl}
                                keepMounted
                                open={Boolean(profileAnchorEl)}
                                onClose={handleClose}
                                xs={{zIndex: 4000}}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 3,
                                        zIndex: 4000,
                                        width: 200,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 4001,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => pushTo('/profile')}>
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Your Profile</ListItemText>
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={onSignOut}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Logout</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Toolbar>
            </StyledAppBar>

            <StyledDrawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(false)}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <List>
                    {menuItems.map((item, index) => (
                        <NavLink key={`menu-item-${index}`} style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}} to={item.to}>
                            {({isActive}) => (
                                <ListItem selected={isActive} disablePadding sx={{display: 'block'}}>
                                    <ListItemButton
                                        onClick={() => {
                                            navigate(item.to);
                                        }}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 'auto',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={item.label}
                                            sx={theme => ({
                                                transition: theme.transitions.create(['opacity', 'padding'], {
                                                    easing: theme.transitions.easing.sharp,
                                                    duration: theme.transitions.duration.leavingScreen
                                                }),
                                                opacity: open ? 1 : 0,
                                                pl: open ? 3 : 0
                                            })}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </NavLink>
                    ))}
                </List>
            </StyledDrawer>

            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader />

                <HeaderBreadcrumbs />

                {children}
            </Box>
        </Box>
    );
};

export default AppContainer;
