import React, {useContext} from 'react';
import {Container, Button, Box, Tabs, Tab, Chip} from '@mui/material';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import moment from 'moment';
import {getFirestore, collection, query, where, orderBy} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {formatMoney} from 'accounting';

import firebaseApp from '../firebase.js';

import {UserContext} from '../contexts/userContext';

import {populateEvents} from '../data/utils';

import Grid from '../components/firestore/Grid';

import {ApproveButton, DeclineButton} from './event/common';
import EventAutocomplete from '../form/EventAutocomplete';

const db = getFirestore(firebaseApp);
const eventsRef = collection(db, 'events');

const dateFormatter = params => {
    const {value} = params;
    if (!value) {
        return '-';
    }

    return moment(value.toDate()).format('DD/MM/YY');
};

const booleanFormatter = params => {
    const {value} = params;

    return value ? 'Y' : 'N';
};

const agoDateFormatter = params => {
    const {value} = params;
    if (!value) {
        return '-';
    }

    return moment(value.toDate()).fromNow();
};

const titleColumn = {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: params => {
        const {row} = params;
        const {title, body} = row;

        return (
            <Box className="MuiDataGrid-cellContent" sx={{py: 2}}>
                <div><strong>{title}</strong></div>
                {body && <div style={{opacity: 0.7, fontSize: '0.675rem'}}>{body}</div>}
            </Box>
        );
    }
};

const pendingColumns = [
    titleColumn,
    {field: 'createdAt', headerName: 'Submitted', width: 150, valueFormatter: agoDateFormatter},
    {field: 'date', headerName: 'Date', width: 150, valueFormatter: dateFormatter},
    // {field: 'minPlayers', headerName: 'Min Players', width: 150},
    // {field: 'maxPlayers', headerName: 'Max Players', width: 150},
    {
        field: 'actions',
        headerName: '',
        width: 220,
        type: 'actions',
        renderCell: params => {
            const {row} = params;
            const {uid, status, total} = row;

            if (status === 'awaitingPayment') {
                return `Awaiting Payment (${formatMoney(total / 100)})`;
            }

            return (
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <ApproveButton uid={uid} sx={{mr: 1}} />
                    <DeclineButton uid={uid} />
                </Box>
            )
        },
        disableClickEventBubbling: true
    }
];

const declinedColumns = [
    titleColumn,
    {field: 'createdAt', headerName: 'Submitted', width: 150, valueFormatter: agoDateFormatter},
    {field: 'date', headerName: 'Date', width: 150, valueFormatter: dateFormatter},
    {field: 'declinedReason', headerName: 'Reason', flex: 1},
    {
        field: 'approve',
        headerName: '',
        type: 'actions',
        renderCell: params => {
            const {row} = params;
            const {uid} = row;

            return <ApproveButton uid={uid} />;
        },
        disableClickEventBubbling: true
    }
];

const canceledColumns = [
    titleColumn,
    {field: 'createdAt', headerName: 'Submitted', width: 150, valueFormatter: agoDateFormatter},
    {field: 'canceled', headerName: 'Canceled', width: 150, valueFormatter: dateFormatter},
    {field: 'date', headerName: 'Date', width: 150, valueFormatter: dateFormatter}
];

const columns = [
    titleColumn,
    {field: 'locked', headerName: 'Locked?', width: 150, renderCell: params => {
        const {row} = params;
        const {status} = row;

        return (
            <div className="MuiDataGrid-cellContent">
                {status === 'locked' && <Chip label="Locked" size="small" />}
            </div>
        );
    }},
    {field: 'date', headerName: 'Date', width: 150, valueFormatter: dateFormatter},
    {field: 'isPrivate', headerName: 'Private?', width: 150, valueFormatter: booleanFormatter},
    {field: 'attending', headerName: 'Attending', width: 150, renderCell: params => {
        const {row} = params;
        const {attendees = [], maxPlayers} = row;

        return `${attendees.length}/${maxPlayers || '-'}`;
    }}
];

const SearchForm = () => {
    const methods = useForm({
        defaultValues: {
            course: null
        },
        mode: 'onBlur'
    });
    const navigate = useNavigate();

    return (
        <FormProvider {...methods}>
            <Box component="form" sx={{mb: 3}}>
                <EventAutocomplete
                    label="Find Event"
                    name="event"
                    fullWidth
                    onChange={id => {
                        if (id) {
                            navigate(`/events/${id}`);
                        }
                    }}
                />
            </Box>
        </FormProvider>
    );
};

const EventsGrid = props => {
    const {columns} = props;
    let {eventsQuery} = props;
    const navigate = useNavigate();
    const {userState} = useContext(UserContext);
    const {userData} = userState;
    const {role, course} = userData || {};

    const isCourse = role === 'COURSE';
    const courseUid = typeof course === 'object' ? course.uid : course;
    
    try {
        eventsQuery = isCourse ? query(eventsQuery, where('course', '==', courseUid)) : eventsQuery;
    } catch(e) {
        eventsQuery = undefined;
    }

    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{flexGrow: 1, minHeight: 400}}>
                <Grid
                    query={eventsQuery}
                    dataModifier={rows => populateEvents(rows)}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    disableSelectionOnClick
                    onCellClick={(cell, event) => {
                        if (['approve', 'decline'].includes(cell.field)) {
                            event.stopPropagation();
                        }
                    }}
                    onRowClick={params => {
                        const {row} = params;

                        navigate(`/events/${row.id}`);
                    }}
                    getRowClassName={params => `event-row--${params.row.status}`}
                />
            </div>
        </div>
    );
};

const TabPanel = props => {
    const {hidden, children} = props;

    return (
        <Box sx={{pt: 1, pb: 2}} style={{display: hidden ? 'none' : 'block', width: '100%'}}>
            {children}
        </Box>
    );
};

export default props => {
    const {view} = props;

    const {userState} = useContext(UserContext);
    const {userData} = userState;
    const {role} = userData || {};

    const isCourse = role === 'COURSE';

    const tabs = [
        {label: 'Pending', path: '', columns: pendingColumns, query: query(eventsRef, where('status', 'in', ['pending', 'awaitingPayment']), orderBy('createdAt', 'desc'))},
        {label: 'Declined', path: 'declined', columns: declinedColumns, query: query(eventsRef, where('status', '==', 'declined'))},
        {label: 'Canceled', path: 'canceled', columns: canceledColumns, query: query(eventsRef, where('status', '==', 'canceled'))},
        {label: 'Upcoming', path: 'upcoming', query: query(eventsRef, where('status', 'in', ['approved', 'locked']), where('date', '>', new Date()), orderBy('date'))},
        {label: 'Awaiting Results', path: 'awaiting', query: query(eventsRef, where('status', 'in', ['approved', 'locked']), where('date', '<', new Date()), orderBy('date'))},
        {label: 'Complete', path: 'complete', query: query(eventsRef, where('status', '==', 'ended'), orderBy('date'))},
    ];

    const navigate = useNavigate();
    const activeTabIndex = view ? tabs.findIndex(tab => tab.path === view) : 0;

    return (
        <Container>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                <Button component={RouterLink} variant="contained" to="/events/new">Add Event</Button>
            </Box>

            {!isCourse && <SearchForm />}

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={activeTabIndex} onChange={(e, index) => navigate(`/events/${tabs[index].path}`)}>
                    {tabs.map((tab, index) => (
                        <Tab key={`tab-label-${index}`} label={tab.label} />    
                    ))}
                </Tabs>
            </Box>

            {tabs.map((tab, index) => (
                <TabPanel key={`tab-${index}`} hidden={activeTabIndex !== index}>
                    {activeTabIndex === index && <EventsGrid columns={tab.columns || columns} eventsQuery={tab.query} />}
                </TabPanel>
            ))}
        </Container>
    );
};
