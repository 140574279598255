import React, {useState, useEffect, useCallback} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {get} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {getFirestore, doc, getDoc, onSnapshot} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {Chip, Avatar} from '@mui/material';

import firebaseApp from '../../firebase.js';

import UserSelection from '../../modals/UserSelection';

import {MoveSingleToGroupButton} from './common';

const functions = getFunctions(firebaseApp);
const addSingleToEvent = httpsCallable(functions, 'addSingleToEvent');

export default () => {
    const [loading, setLoading] = useState(true);
    const [addingSingle, setAddingSingle] = useState(false);
    const [userSelectionOpen, setUserSelectionOpen] = useState(false);
    const [singles, setSingles] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const {id: eventUid} = useParams();

    const db = getFirestore(firebaseApp);

    const columns = [
        {
            field: 'displayName',
            headerName: 'User',
            flex: 1,
            renderCell: params => {
                const {row} = params;
                const {handicap} = row;

                return (
                    <>
                        {get(params, 'row.displayName')}
                        <Chip sx={{ml: 1}} size="small" avatar={<Avatar>H</Avatar>}  label={handicap} />
                    </>
                );
            }
        },
        {
            field: '',
            headerName: '',
            type: 'actions',
            width: 150,
            renderCell: params => {
                const {row} = params;
                const {uid} = row;

                return <MoveSingleToGroupButton eventUid={eventUid} userUid={uid} />;
            },
            disableClickEventBubbling: true
        }
    ];

    const handleEvent = useCallback(async eventRaw => {
        const {singles = []} = eventRaw.data();

        // TODO this should be done with a single query
        if (singles.length) {
            const users = {};
            for (const user of singles) {
                const userRef = doc(db, 'users', user);
                const userSnap = await getDoc(userRef);
                users[user] = {
                    id: user,
                    uid: user,
                    ...userSnap.data()
                };
            }

            setSingles(singles.map(user => users[user]));
        } else {
            setSingles([]);
        }

        setLoading(false);
    });

    useEffect(() => {
        const ref = doc(db, 'events', eventUid);
        const unsubscribe = onSnapshot(ref, raw => {
            handleEvent(raw);
        });

        return () => unsubscribe();
    }, []);

    const onAddSingle = useCallback(async userUid => {
        setUserSelectionOpen(false);

        try {
            setAddingSingle(true);

            await addSingleToEvent({
                event: eventUid,
                user: userUid
            });

            enqueueSnackbar('Invited to Event', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setAddingSingle(false);
    });

    return (
        <div style={{width: '100%'}}>
            <DataGrid
                loading={loading}
                autoHeight
                hideFooter
                rows={singles}
                columns={columns}
                disableSelectionOnClick
            />

            <LoadingButton
                variant="contained"
                size="small"
                sx={{mt: 1}}
                onClick={() => setUserSelectionOpen(true)}
                loading={addingSingle}
            >
                Add Single
            </LoadingButton>

            <UserSelection
                title="Add Single"
                body="Select a user to add as a single"
                buttonText="Add"
                open={userSelectionOpen}
                onClose={() => setUserSelectionOpen(false)}
                onSelect={onAddSingle}
            />
        </div>
    );
};